import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {MatLegacySelectChange as MatSelectChange} from '@angular/material/legacy-select';
import {IQuestion} from '@px/shared/api';
import {ISurveyResult} from '../../interfaces/survey-result.interface';

@Component({
  selector: 'px-survey-content',
  templateUrl: './survey-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SurveyContentComponent {
  @Input() currentQuestionIndex = 0;
  @Input() maxQuestionIndex = 0;
  @Input() questions: (IQuestion & {optionsIds: number[]; selectedOptionId: number})[] = [];
  @Input() isSaving = false;

  @Output() save$ = new EventEmitter<void>();
  @Output() optionChange$ = new EventEmitter<ISurveyResult>();
  @Output() surveySelect$ = new EventEmitter<number>();

  @Output() surveyStart$ = new EventEmitter<void>();
  @Output() next$ = new EventEmitter<void>();

  compareOptions(o1Id: string, o2Id: string): boolean {
    return o1Id === o2Id;
  }

  optionChange($event: MatSelectChange, question: IQuestion): void {
    this.optionChange$.emit({questionId: question.id, optionId: $event.value});
  }

  surveyStart(): void {
    this.surveyStart$.emit();
  }

  surveySelect(index: number): void {
    this.surveySelect$.emit(index);
  }

  next(): void {
    this.next$.emit();
  }

  send(): void {
    this.save$.emit();
  }
}
