import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, inject, NgModule} from '@angular/core';

import {RouterModule} from '@angular/router';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';

import {AppComponent} from './core/app-component/app.component';
import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';

import {ROOT_REDUCER, USER_PROVIDED_META_REDUCERS} from './store/app/app.state';
import {BrowserService} from '@px/shared/browser';
import {AppEffects} from './store/app/app.effects';
import {IconsService} from './shared/svg-icons/icons.service';
import {SvgIconsModule} from './shared/svg-icons/svg-icons.module';
import {SentryPssErrorHandlerService} from './services/sentry-pss-error-handler.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  CONFIG_CAT_ASYNC_INITIALIZER,
  ConfigCatAsyncProvider,
  ConfigCatFeatureFlagsConfiguratorService,
  FEATURE_FLAGS_CONFIGURATOR,
  Languages,
  PlatformEnvironment,
} from '@px/shared/env';
import {PSSPlatformEnvironment} from './platform-environment';
import {FeatureTrackingSmartSlidesModule} from '@px/pss/feature-tracking';
import {TrackingInfoService} from './services/tracking/tracking-info.service';
import {TRACKING_INFO_TOKEN} from './services/tracking/tracking-info-token';
import {
  ApiModule,
  AUTH_SERVICE,
  HttpXSRFInterceptor,
  IAuthService,
  IPreferencesService,
  LOGOUT_PATH,
  PREFERENCES_SERVICE,
  PreferencesGqlService,
  PreferencesService,
  ScopeInterceptor,
  SLIDESHOW_CLIENT_SERVICE,
  SlideshowClientService,
} from '@px/shared/api';
import {SharedFormattersModule} from '@px/shared/formatters';
import {FeatureFileUploadModule} from '@px/shared-data-access-file-upload';
import {environment} from '../environments/environment';
import {ConnectionIssueDomainModule} from '@px/connection-issue/domain';
import {NoAuthInterceptor, PhotographerAuthFeatureAuthModule} from '@px/photographer-auth/feature-auth';
import {
  PHOTOGRAPHER_BACKEND_CLIENT_ID,
  PhotographerAuthDomainModule,
  PhotographerAuthFacade,
} from '@px/photographer-auth/domain';
import {PhotographerAuthApiModule} from '@px/photographer-auth/api';
import {
  BACKEND_CONTEXT,
  BackendContextService,
  SharedDataAccessBackendModule,
} from '@px/shared-data-access-backend-context';
import {AppRoutes} from './models/enums/routes.enum';
import {DEFAULT_STORAGE_SALT} from '@px/shared-data-access-local-storage';
import {TranslateMessageFormatCompiler} from '@px/shared/translate-message-format-compiler';
import {PIC_TOKEN_INTERCEPTOR, SessionProviderFacade, SharedSessionProviderModule} from '@px/shared/session-provider';
import {WINDOW_PROVIDER} from '@px/cdk/window';
import {ApolloOptionResolverBatchService, ApolloOptionsModule} from '@px/shared/data-access/apollo-options';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, NormalizedCacheObject} from '@apollo/client';
import {PX_EVENTS_API, PxEventsDomainModule} from '@px/px-events/domain';
import {PxEventsApiModule, PxEventsGraphqlApiService} from '@px/px-events/api';
import {MdsLayoutModule} from '@pui/cdk/layout';
import {BILLING_PROVIDER_SETTINGS, IBillingProviderSettings} from '@ps/pricing-domain';
import {
  ISubscriptionManagerConfig,
  SUBSCRIPTION_MANAGER_CONFIG,
  SubscriptionManagerFeatureModule,
} from '@px/subscription-manager/feature-manager';
import {AUDIO_PIC_HOST, AUDIO_S3_HOST, MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE} from '@px/pss-audio';
import {DATA_DOG_SETUP_INITIALIZER, DataDogService} from '@px/shared/data-access/data-dog';
import {SessionStorageModule} from '@px/shared/data-access/session-storage';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {ADDITIONAL_SUBSCRIPTION_ACTIVATION_CHECK} from '@px/subscription-manager/domain';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FIRST_PROMOTER_INITIALIZER} from '@px/shared/data-access/first-promoter';
import {FeatureUnsupportedBrowserModule} from '@px/legacy/feature-unsupported-browser';
import {FeaturePageMobileNotSupportedModule} from '@px/legacy/feature-page-mobile-not-supported';
import {TranslationService} from './shared/services/translations.service';
import {UtilsService} from './shared/services/utils.service';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MatLegacySnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {caIcons, mdsProvideIcons, pxIcons} from '@pui/components/icon-core';
import {CroppingService} from './services/cropping.service';
import {LottieModule} from 'ngx-lottie';
import {STUDIO_LOGO_S3_HOST} from '@px/shared/studio-logo-hosting';
import {MAX_IMAGE_ASSETS_UPLOADING_QUEUE_SIZE} from '@px/pss/slideshow-editor/domain';
import {IMAGE_FILES_S3_HOST} from '@px/shared/image-hosting';
import {WEB_PLAYER_JPEG_ONLY} from '@px/pss/web-player';
import {PSSPlatformFeatures} from './core/interfaces/platform-config';
import {AuthService} from './auth/services/auth.service';
import {AudioCategoryPipe, AudioEnergyPipe, TempoPipe} from '@px/pss-feature-audio-browser';
import {IPSSAuthService} from './auth/models/auth-service.interface';
import {PSSAuthFacade} from './auth/services/pss-auth-facade.service';

export const createTranslateLoader = (
  http: HttpClient,
  platformEnvironment: PSSPlatformEnvironment
): TranslateHttpLoader => {
  // TODO: there will be fix version instead nanoid
  return new TranslateHttpLoader(
    http,
    './assets/i18n/frontend_locale-',
    `.json?${platformEnvironment.getQueryString()}`
  );
};

export function playerFactory(): Promise<typeof import('lottie-web')> {
  return import('lottie-web');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    ApiModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    AppRoutingModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: Languages.en,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, PSSPlatformEnvironment],
      },
    }),
    StoreRouterConnectingModule.forRoot({routerState: RouterState.Minimal}),
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers: USER_PROVIDED_META_REDUCERS,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: !environment.production,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    SvgIconsModule,
    FeatureTrackingSmartSlidesModule,
    SharedFormattersModule,
    FeatureFileUploadModule,
    ConnectionIssueDomainModule,
    SharedDataAccessBackendModule,
    PhotographerAuthApiModule,
    PhotographerAuthFeatureAuthModule,
    PhotographerAuthDomainModule,
    SharedSessionProviderModule,
    ApolloOptionsModule,
    PxEventsApiModule,
    MdsLayoutModule,
    SubscriptionManagerFeatureModule,
    SessionStorageModule,
    FeatureUnsupportedBrowserModule,
    FeaturePageMobileNotSupportedModule,
    MatLegacySnackBarModule,
    MatLegacyDialogModule,
    LottieModule.forRoot({player: playerFactory}),
    PxEventsDomainModule,
  ],
  exports: [RouterModule],
  providers: [
    TranslationService,
    UtilsService,
    DataDogService,
    CroppingService,
    ConfigCatAsyncProvider,
    PreferencesService,
    BrowserService,
    AudioEnergyPipe,
    AudioCategoryPipe,
    TempoPipe,
    DEFAULT_STORAGE_SALT,
    PIC_TOKEN_INTERCEPTOR,
    WINDOW_PROVIDER,
    DATA_DOG_SETUP_INITIALIZER,
    FIRST_PROMOTER_INITIALIZER,
    CONFIG_CAT_ASYNC_INITIALIZER,
    {provide: LOGOUT_PATH, useValue: AppRoutes.LOGOUT},
    {provide: TRACKING_INFO_TOKEN, useClass: TrackingInfoService},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ScopeInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NoAuthInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: (icons: IconsService) => async (): Promise<void> => await icons.registerIcons(),
      multi: true,
      deps: [IconsService],
    },
    mdsProvideIcons({...pxIcons, ...caIcons}),
    {provide: ErrorHandler, useClass: SentryPssErrorHandlerService},
    {provide: BACKEND_CONTEXT, useExisting: BackendContextService},
    {
      provide: PHOTOGRAPHER_BACKEND_CLIENT_ID,
      useFactory: (platformEnvironment: PlatformEnvironment): string => platformEnvironment.PX_OAUTH_CLIENT_ID ?? '',
      deps: [PlatformEnvironment],
    },
    {
      provide: FEATURE_FLAGS_CONFIGURATOR,
      useClass: ConfigCatFeatureFlagsConfiguratorService,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        apolloOptionsResolver: ApolloOptionResolverBatchService
      ): ApolloClientOptions<NormalizedCacheObject> => apolloOptionsResolver.resolve(),
      deps: [ApolloOptionResolverBatchService],
    },
    {
      provide: PX_EVENTS_API,
      useExisting: PxEventsGraphqlApiService,
    },
    {
      provide: PRODUCT_FAMILY,
      useValue: ProductFamily.PSS,
    },
    {
      provide: BILLING_PROVIDER_SETTINGS,
      useFactory: (platform: PSSPlatformEnvironment): IBillingProviderSettings => {
        return {
          site: platform.CHARGEBEE_SITE,
          sdkUrl: platform.CHARGEBEE_SDK_URL,
          apiKey: platform.CHARGEBEE_API_KEY,
        } as IBillingProviderSettings;
      },
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE,
      useFactory: (platform: PSSPlatformEnvironment): number => platform.MAX_AUDIO_ASSETS_UPLOADING_QUEUE_SIZE,
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: AUDIO_PIC_HOST,
      useFactory: (platform: PSSPlatformEnvironment): string => platform.API_IMG,
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: AUDIO_S3_HOST,
      useFactory: (platform: PSSPlatformEnvironment): string => platform.UPLOADER_API,
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: STUDIO_LOGO_S3_HOST,
      useFactory: (platform: PSSPlatformEnvironment): string => platform.UPLOADER_API,
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: IMAGE_FILES_S3_HOST,
      useFactory: (platform: PSSPlatformEnvironment): string => {
        if (platform.hasFeature(PSSPlatformFeatures.CLOUDFRONT_IMAGE_LOADING)) {
          return platform.UPLOADER_API_CLOUDFRONT;
        } else {
          return platform.UPLOADER_API;
        }
      },
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: MAX_IMAGE_ASSETS_UPLOADING_QUEUE_SIZE,
      useFactory: (platform: PSSPlatformEnvironment): number => platform.COMMON_SETTINGS.maxUploadStreams,
      deps: [PSSPlatformEnvironment],
    },
    {
      provide: SUBSCRIPTION_MANAGER_CONFIG,
      useFactory: (
        platform: PSSPlatformEnvironment,
        translateService: TranslateService
      ): ISubscriptionManagerConfig => ({
        activationWaitingTimeout: platform.SUBSCRIPTION_ACTIVATION_WAITING_TIMEOUT,
        subscriptionActivatedActionBtnText: translateService.instant('Refresh page'),
        activationAdditionalSuccessText: translateService.instant(
          'Please refresh the page for the changes to take effect in SmartSlides'
        ),
      }),
      deps: [PSSPlatformEnvironment, TranslateService],
    },
    {provide: SLIDESHOW_CLIENT_SERVICE, useClass: SlideshowClientService},
    {
      provide: AuthService,
      useFactory: (): IPSSAuthService => {
        const platform = inject(PSSPlatformEnvironment);
        return platform.hasFeature(PSSPlatformFeatures.AUTHORIZATION_GRAPHQL_API)
          ? new PSSAuthFacade()
          : new AuthService();
      },
    },
    {
      provide: AUTH_SERVICE,
      useExisting: AuthService,
    },
    {
      provide: PREFERENCES_SERVICE,
      useFactory: (): IPreferencesService => {
        return inject(PSSPlatformEnvironment).hasFeature(PSSPlatformFeatures.PREFERENCES_GRAPHQL_API)
          ? inject(PreferencesGqlService)
          : inject(PreferencesService);
      },
    },
    {
      provide: ADDITIONAL_SUBSCRIPTION_ACTIVATION_CHECK,
      useFactory:
        (authservice: IAuthService) =>
        (productId: string): Observable<boolean> =>
          authservice.logInCheck().pipe(map(lc => lc.data.user_plan_id === productId)),
      deps: [AUTH_SERVICE],
    },
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
    {
      provide: APP_INITIALIZER,
      useFactory: (): (() => void) => {
        const phSessionService = inject(PhotographerAuthFacade);
        const sessionProviderFacade = inject(SessionProviderFacade);

        return (): void => {
          sessionProviderFacade.attach(phSessionService);
        };
      },
      multi: true,
    },
    {
      provide: WEB_PLAYER_JPEG_ONLY,
      useFactory: (): boolean => inject(PSSPlatformEnvironment).hasFeature(PSSPlatformFeatures.JPEG_ONLY_IN_PLAYER),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(platform: PSSPlatformEnvironment) {
    platform.printVersion();
  }
}
