import {Inject, Injectable, Injector} from '@angular/core';

import {IConfig} from '../entities/config.interface';
import {DOCUMENT} from '@angular/common';
import {
  IPxBillingProvider,
  PxBillingProviderCheckoutSession,
  PxBillingProviderPortalSession,
} from '../entities/chargebee.interface';
import {BehaviorSubject, Subject} from 'rxjs';
import {CHARGEBEE} from '../entities/tokens/chargebee.token';
import {IBillingProviderFacade} from '../entities/billing-provider-facade.interface';

@Injectable()
export class BillingProviderFacade implements IBillingProviderFacade {
  private billingProvider?: IPxBillingProvider;

  isCheckoutOpened$ = new Subject<void>();
  isCheckoutClosed$ = new Subject<void>();

  subscriptionChanged$ = new Subject<void>();
  subscriptionCancelled$ = new Subject<void>();

  isCustomerPortalOpened$ = new Subject<void>();
  isCustomerPortalClosed$ = new Subject<void>();

  isCheckoutSuccess$ = new Subject<void>();
  isCheckoutError$ = new Subject<Error>();

  isReady$ = new BehaviorSubject(false);

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly injector: Injector
  ) {}

  async setUp(sdkUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const initScript = this.document.createElement('script');
      initScript.src = sdkUrl;

      this.document.head.appendChild(initScript);
      initScript.onload = (): void => {
        resolve();
      };

      initScript.onerror = (): void => {
        reject();
      };
    });
  }

  initialize(config: IConfig): void {
    const chargebee = this.injector.get(CHARGEBEE);

    chargebee.init({
      site: config.site,
      api_key: config.apiKey,
      iframeOnly: config.iframeOnly,
    });

    this.billingProvider = chargebee.getInstance();
    this.isReady$.next(true);
  }

  openCheckout(session: PxBillingProviderCheckoutSession): void {
    this.billingProvider?.openCheckout({
      hostedPage: () => Promise.resolve(session),
      loaded: () => this.isCheckoutOpened$.next(),
      close: () => this.isCheckoutClosed$.next(),
      success: () => this.isCheckoutSuccess$.next(),
      error: e => this.isCheckoutError$.next(e),
    });
  }

  openCustomerPortal(session: PxBillingProviderPortalSession): void {
    this.billingProvider?.setPortalSession(() => Promise.resolve(session));
    this.billingProvider?.createChargebeePortal()?.open({
      subscriptionChanged: () => {
        this.subscriptionChanged$.next();
      },
      subscriptionCancelled: () => {
        this.subscriptionCancelled$.next();
      },
      close: () => {
        this.isCustomerPortalClosed$.next();
        this.billingProvider?.logout();
      },
      loaded: () => {
        this.isCustomerPortalOpened$.next();
      },
    });
  }

  close(): void {
    this.billingProvider?.closeAll();
  }
}
