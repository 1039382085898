export enum AudioCategory {
  BEATS = 'BEATS',
  CALM_MELODIES = 'CALM_MELODIES',
  EMOTIONAL = 'EMOTIONAL',
  EPIC_BM = 'EPIC_BM',
  HAPPY_FOLK = 'HAPPY_FOLK',
  LOVE_SONGS = 'LOVE_SONGS',
  PARTY_TIME = 'PARTY_TIME',
  USER_AUDIO = 'USER_AUDIO',
}

/** @deprecated dont depend on ids use AudioCategory instead */
export enum AudioCategoryId {
  MY_UPLOADS = 0,
  PARTY_TIME = 1,
  HAPPY = 2,
  CALM_MELODIES = 3,
  BEATS = 4,
  EMOTIONAL = 5,
  LOVE_SONGS = 6,
  EPIC_BEAT_MATCHING = 7,
}
