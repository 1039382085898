import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {ComponentRef, inject, Injectable, Injector} from '@angular/core';
import {filter, ReplaySubject, Subject, take} from 'rxjs';
import {MdsDialogRootComponent} from '../components/dialog-root/dialog-root.component';
import {IMdsDialogConfig} from '../interfaces/dialog-config.interface';
import {MdsDialogRef} from '../interfaces/dialog-ref';
import {MdsDialogState} from '../interfaces/dialog-state.enum';
import {MDS_DIALOG_DATA, MDS_DIALOG_SERVICE} from '../interfaces/dialog.tokens';
import {IMdsDialogService} from './dialog-service.interface';

@Injectable()
export class MdsDialogService implements IMdsDialogService {
  private readonly overlay = inject(Overlay);
  private readonly injector = inject(Injector);
  private readonly mdsDialogService: IMdsDialogService | null = inject(MDS_DIALOG_SERVICE, {
    optional: true,
    skipSelf: true,
  });

  private DEFAULT_CONFIG: IMdsDialogConfig<null> = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-dark-backdrop',
    backdropClose: true,
    escClose: true,
  };

  private openDialogsInternal: MdsDialogRef<unknown, unknown>[] = [];

  afterOpened$ = this.mdsDialogService?.afterOpened$ ?? new ReplaySubject<void>(1);
  afterAllClosed$ = this.mdsDialogService?.afterAllClosed$ ?? new ReplaySubject<void>(1);

  get openDialogs(): MdsDialogRef<unknown, unknown>[] {
    return this.mdsDialogService?.openDialogs ?? this.openDialogsInternal;
  }

  set openDialogs(value: MdsDialogRef<unknown, unknown>[]) {
    if (this.mdsDialogService) {
      this.mdsDialogService.openDialogs = value;
      return;
    }

    this.openDialogsInternal = value;
  }

  private attachDialogContainer<C, D, R>(
    dialog: ComponentType<C>,
    overlayRef: OverlayRef,
    config: IMdsDialogConfig<D>,
    dialogRef: MdsDialogRef<C, R>
  ): MdsDialogRootComponent<C> {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(MdsDialogRootComponent, null, injector) as ComponentPortal<
      MdsDialogRootComponent<C>
    >;

    const containerRef: ComponentRef<MdsDialogRootComponent<C>> = overlayRef.attach(containerPortal);
    dialogRef.containerRef = containerRef;

    containerRef.instance.backdropClick$.subscribe(() => {
      if (!config.backdropClose) return;
      dialogRef.close();
    });

    containerRef.instance.noPaddingOnSmallViewport = config.noPaddingOnSmallViewport ?? false;

    containerRef.instance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'enter'),
        take(1)
      )
      .subscribe(() => {
        dialogRef.afterOpenedInternal.next(true);
        dialogRef.setState(MdsDialogState.OPEN);
      });

    this.attachDialogContent(dialog, containerRef);

    return containerRef.instance;
  }

  private attachDialogContent<C>(
    dialog: ComponentType<C>,
    containerRef: ComponentRef<MdsDialogRootComponent<C>>
  ): void {
    const contentPortal = new ComponentPortal(dialog);
    containerRef.instance.attachPortal(contentPortal);
  }

  private createInjector<D, R, C>(config: IMdsDialogConfig<D>, dialogRef: MdsDialogRef<C, R>): Injector {
    return Injector.create({
      providers: [
        {provide: MdsDialogRef, useValue: dialogRef},
        {provide: MDS_DIALOG_DATA, useValue: config},
      ],
      parent: this.injector,
      name: 'MdsDialogInjector',
    });
  }

  private createOverlay<D>(config: IMdsDialogConfig<D>): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig<D>(config: IMdsDialogConfig<D>): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      disposeOnNavigation: true,
      scrollStrategy: config.scrollStrategy ?? this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }

  open<C, D, R>(dialog: ComponentType<C>, config: IMdsDialogConfig<D> = {}): MdsDialogRef<C, R> {
    const dialogConfig = {...this.DEFAULT_CONFIG, ...config} as IMdsDialogConfig;
    const overlayRef = this.createOverlay(dialogConfig);
    const dialogRef = new MdsDialogRef<C, R>(overlayRef, dialogConfig);
    this.attachDialogContainer(dialog, overlayRef, dialogConfig, dialogRef);

    this.openDialogs.push(dialogRef as MdsDialogRef<unknown, unknown>);
    (this.afterOpened$ as Subject<void>).next(undefined);

    dialogRef.afterClosed().subscribe(() => {
      this.openDialogs = this.openDialogs.filter(item => item !== dialogRef);

      if (!this.openDialogs.length) {
        (this.afterAllClosed$ as Subject<void>).next(undefined);
      }
    });

    return dialogRef;
  }
}
