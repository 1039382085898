import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AbTestsService} from './service';

@Directive({
  selector: '[abTestVersion]',
})
export class AbTestVersionDirective implements OnInit {
  private _versions: string[];
  private _scope: string;

  constructor(
    private _service: AbTestsService,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) {}

  ngOnInit() {
    if (this._service.shouldRender(this._versions, this._scope)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }

  @Input()
  set abTestVersion(value: string) {
    this._versions = value.split(',');
  }

  @Input()
  set abTestVersionScope(value: string) {
    this._scope = value;
  }
}
