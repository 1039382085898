import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'px-upgrade-reminder',
  templateUrl: './upgrade-reminder.component.html',
  styleUrls: ['./upgrade-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeReminderComponent {
  @Input() hasUpgradeButton?: boolean;
  @Input() availableCount?: number;
  @Input() currentCount?: number;

  @Output() upgrade$ = new EventEmitter<void>();

  upgrade(): void {
    this.upgrade$.emit();
  }
}
