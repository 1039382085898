import {inject, Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {SubscriptionStatus} from '../enums/subscription-status';
import {ICustomerSubscription} from '../entities/customer-subscription';
import {map, Observable} from 'rxjs';
import {ICustomerSubscriptionsDataProvider} from '../entities/customer-subscriptions-data-provider';

@Injectable()
export class CustomerSubscriptionsDataService implements ICustomerSubscriptionsDataProvider {
  private readonly customerSubscriptionsQuery = gql<
    {customerSubscriptions: ICustomerSubscription[]},
    {customerId?: string; productFamily?: ProductFamily; status?: SubscriptionStatus}
  >`
    query CustomerSubscriptions($customerId: PxId, $productFamily: ProductFamily, $status: BillingSubscriptionStatus) {
      customerSubscriptions(customerId: $customerId, productFamily: $productFamily, status: $status) {
        id
        package {
          products {
            price {
              id
              name
              billingProductFamily {
                productFamily
              }
              product {
                id
              }
            }
          }
        }
        customer {
          paymentSourceCards {
            brand
            expiryMonth
            expiryYear
            firstName
            lastName
            last4
          }
        }
      }
    }
  `;

  private readonly updateCustomerSubscriptionMutation = gql<
    {updateCustomerSubscription: boolean},
    {subscriptionId: string; priceId: string}
  >`
    mutation UpdateCustomerSubscription($subscriptionId: ID!, $priceId: ID!) {
      updateCustomerSubscription(subscriptionId: $subscriptionId, priceId: $priceId)
    }
  `;

  private readonly cancelCustomerSubscriptionMutation = gql<
    {cancelCustomerSubscription: boolean},
    {subscriptionId: string}
  >`
    mutation CancelCustomerSubscription($subscriptionId: ID!) {
      cancelCustomerSubscription(subscriptionId: $subscriptionId)
    }
  `;

  private readonly apollo = inject(Apollo);

  getCustomerSubscriptions(
    customerId?: string,
    productFamily?: ProductFamily,
    status?: SubscriptionStatus,
    jwtToken?: string
  ): Observable<ICustomerSubscription[]> {
    return this.apollo
      .query({
        query: this.customerSubscriptionsQuery,
        variables: {
          productFamily,
          customerId,
          status,
        },
        fetchPolicy: 'network-only',
        context: jwtToken ? {headers: {authorization: `bearer ${jwtToken}`}} : {},
      })
      .pipe(map(response => response.data.customerSubscriptions));
  }

  updateCustomerSubscription(subscriptionId: string, priceId: string, jwtToken?: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.updateCustomerSubscriptionMutation,
        variables: {
          subscriptionId,
          priceId,
        },
        fetchPolicy: 'network-only',
        context: jwtToken ? {headers: {authorization: `bearer ${jwtToken}`}} : {},
      })
      .pipe(map(response => !!response.data?.updateCustomerSubscription));
  }

  cancelCustomerSubscription(subscriptionId: string, jwtToken?: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.cancelCustomerSubscriptionMutation,
        variables: {
          subscriptionId,
        },
        fetchPolicy: 'network-only',
        context: jwtToken ? {headers: {authorization: `bearer ${jwtToken}`}} : {},
      })
      .pipe(map(response => !!response.data?.cancelCustomerSubscription));
  }
}
