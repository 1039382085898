export enum SubscriptionEventNames {
  CHECKOUT_OPEN = 'CHECKOUT_OPEN',
  CHECKOUT_CLOSE = 'CHECKOUT_CLOSE',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  UPGRADE_SUCCESS = 'UPGRADE_SUCCESS',
  CHECKOUT_ERROR = 'CHECKOUT_ERROR',
  BUY_NOW_CLICK = 'BUY_NOW_CLICK',
  SUBSCRIPTION_ACTIVATION_STATE = 'SUBSCRIPTION_ACTIVATION_STATE',
  SUBSCRIPTION_ACTIVATION_SUCCESS = 'SUBSCRIPTION_ACTIVATION_SUCCESS',
  SUBSCRIPTION_ACTIVATED_ACTION_CLICK = 'SUBSCRIPTION_ACTIVATED_ACTION_CLICK',
}
