<mat-form-field floatLabel="never" [class.is-open]="isSearchOpened">
  <div matPrefix (click)="isSearchOpened = !isSearchOpened">
    <i class="pss-icons_22-search"></i>
    &nbsp;
  </div>
  <input
    matInput="matInput"
    [readonly]="!isSearchOpened"
    type="text"
    placeholder="{{ 'GLOBAL.SEARCH_LABEL' | translate }}"
    [formControl]="searchField"
  />
  <div matSuffix>
    <i
      class="pss-icons_11-close"
      *ngIf="isSearchOpened"
      [class.invisible]="!searchField.value"
      [attr.aria-label]="'Clear'"
      (click)="searchField.setValue('')"
    ></i>
  </div>
</mat-form-field>
