import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ApolloError} from '@apollo/client';
import {
  asyncScheduler,
  BehaviorSubject,
  catchError,
  exhaustMap,
  mergeMap,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs';
import {JWTWaitingTimeout, JWTWrapperAttemptsExpiredError} from '../entities/jwt-wrapper-errors';
import {IJwtWrapper} from '../entities/jwt-wrapper.interface';
import {JWT_WRAPPER_OPTIONS} from '../tokens/jwt-wrapper-options';

@Injectable()
export class JwtWrapperService implements IJwtWrapper {
  private readonly OPTIONS = inject(JWT_WRAPPER_OPTIONS, {optional: true});
  private readonly ATTEMPTS = this.OPTIONS?.attempts ?? 3;
  private readonly TIMEOUT_MS = this.OPTIONS?.timeoutMS ?? 5000;
  private jwt$ = new BehaviorSubject<string | undefined>(undefined);
  private invalidTokenInternal$ = new Subject<string>();
  private emptyTokenInternal$ = new Subject<void>();

  invalidToken$ = this.invalidTokenInternal$.asObservable();
  emptyToken$ = this.emptyTokenInternal$.asObservable();

  jwtRequired = false;

  set jwt(value: string | undefined) {
    this.jwt$.next(value);
  }
  get jwt(): string | undefined {
    return this.jwt$.value;
  }

  private isForbiddenGQLError(e: unknown): boolean {
    return (
      e instanceof ApolloError && !!e.graphQLErrors.length && e.graphQLErrors[0].extensions?.['code'] === 'FORBIDDEN'
    );
  }

  private isForbiddenNetworkError(e: unknown): boolean {
    return (
      e instanceof ApolloError &&
      e.networkError instanceof HttpErrorResponse &&
      [401, 403].includes(e.networkError.status)
    );
  }

  private isForbidden(e: unknown): boolean {
    return this.isForbiddenGQLError(e) || this.isForbiddenNetworkError(e);
  }

  private handleInvalidToken(attempt: number, jwtWaitingCancel$: Observable<void>): Observable<never> {
    if (attempt >= this.ATTEMPTS) {
      //NOTE: in case we get a invalid token several times in a row
      return throwError(() => new JWTWrapperAttemptsExpiredError(this.ATTEMPTS));
    }
    asyncScheduler.schedule(() =>
      this.jwt ? this.invalidTokenInternal$.next(this.jwt) : this.emptyTokenInternal$.next()
    );
    //NOTE: in case we didn't get a valid token in time
    return timer(this.TIMEOUT_MS).pipe(
      takeUntil(jwtWaitingCancel$),
      mergeMap(() => throwError(() => new JWTWaitingTimeout()))
    );
  }

  wrap<T>(func: (token: string | undefined) => Observable<T>): Observable<T> {
    let jwtWaitingCancel$: Subject<void> | null;
    let attempt = 0;

    return this.jwt$.pipe(
      tap(() => {
        if (jwtWaitingCancel$) {
          jwtWaitingCancel$.next();
          jwtWaitingCancel$.complete();
        }
      }),
      mergeMap(token => {
        if (token) {
          return of(token);
        } else if (this.jwtRequired) {
          attempt++;
          jwtWaitingCancel$ = new Subject<void>();
          return this.handleInvalidToken(attempt, jwtWaitingCancel$);
        } else {
          return of(undefined);
        }
      }),

      exhaustMap(token =>
        func(token).pipe(
          catchError((e: ApolloError) => {
            const isForbidden = this.isForbidden(e);

            if (isForbidden) {
              this.jwtRequired = true;
              attempt++;
              jwtWaitingCancel$ = new Subject<void>();

              return this.handleInvalidToken(attempt, jwtWaitingCancel$);
            }

            return throwError(() => e);
          })
        )
      ),
      take(1)
    );
  }
}
