export enum TransitionType {
  AUTO = 'AUTO',
  CROSS_DISSOLVE = 'CROSS_DISSOLVE',
  DIP_TO_BACKGROUND = 'DIP_TO_BACKGROUND',
  HARD_CUT = 'HARD_CUT',
  WIPE_DOWN = 'WIPE_DOWN',
  WIPE_LEFT = 'WIPE_LEFT',
  WIPE_RIGHT = 'WIPE_RIGHT',
  WIPE_UP = 'WIPE_UP',
}

export enum TransitionTypeId {
  CROSS_FADE = 0,
  AUTO = 7,
  HARD_CUT = 1,
  WIPE_UP = 2,
  WIPE_DOWN = 3,
  WIPE_LEFT = 4,
  WIPE_RIGHT = 5,
  DIP_TO_BACKGROUND = 6,
}
