export type PxValue = string | number;

export function pxValueAttribute(value: PxValue): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  const number = parseFloat(value as string);
  if (!isNaN(number)) {
    return `${number}px`;
  }

  return value.includes('px') ? value.slice(0, value.indexOf('px') + 2) : '0px';
}
