export enum Events {
  BILLING_CHECKOUT_OPEN = 'P: Click Buy now Button',
  BILLING_CHECKOUT_SUCCESS = 'P: Purchase succeeded',
  BILLING_CHECKOUT_FAILED = 'P: Purchase failed',
  BM_TARGET_HIT = 'P: Beat-Matching Target Hit',
  CLICK_REDISTRIBUTE_BUTTON = 'P: Click Redistribute Button',
  CLICK_UPGRADE_BUTTON = 'P: Click Upgrade Button',
  CLICK_UPGRADE_NOW_LINK = 'P: Click Ugrade Now Link',
  CLICK_THUMBNAIL_PLAY_BUTTON = 'P: Click Thumbnail Play Button',
  CLICK_TIMELINE_PLAY_BUTTON = 'P: Click Timeline Play Button',
  CREATE_NEW_SLIDESHOW = 'P: Create New Slideshow',
  DELETE_SEGMENT = 'P: Delete Segment',
  DOWNLOAD_VIDEO = 'P: Download Video',
  DUPLICATE_PHOTO = 'P: Duplicate Photo',
  ENABLE_BM_MANUALLY = 'P: Enable Beat-Matching Manually',
  LOGIN = 'P: Login',
  NAVIGATE_TO_SLIDESHOW_MANAGER = 'P: Navigate to Slideshow Manager',
  PUBLISH_SLIDESHOW = 'P: Publish Slideshow',
  PUBLISH_SONG = 'P: Publish Song',
  REACH_THEME_VIEWER = 'P: Reach Theme Viewer',
  START_NEW_SLIDESHOW = 'P: Start New Slideshow',
  UPDATE_SLIDESHOW = 'P: Update Slideshow',
  UPDATE_SONG = 'P: Update Song',
  SKIP_MUSIC_SELECTION = 'P: Skip music',
  ADD_SONG_TO_SEGMENT = 'P: Add song to segment',
  IMAGE_UPLOAD = 'P: Image Upload',
  IMAGE_UPLOADING_ERROR = 'P: Image Uploading Error',
  SAMPLE_CONTENT_ACTION = 'P: Sample Content Action',
  CROP_ACTION = 'P: Crop Action',

  CLIENT_DOWNLOAD_VIDEO = 'Download Video',
}

export enum SampleContentActions {
  OPEN_PUBLISHED = 'Open published',
  EDIT_PUBLISHED = 'Edit published',
  EDIT_NON_PUBLISHED = 'Edit non-published',
  DELETE = 'Delete',
  DUPLICATE = 'Duplicate',
}
