export enum MDS_DROPDOWN_TYPE {
  default = 'default',
  base = 'base',
  clean = 'clean',
  cleanSmall = 'cleanSmall',
  menu = 'menu',
  select = 'select',
  hint = 'hint',
}

export enum MDS_DROPDOWN_POSITION {
  base = 'base',
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  bottomCenter = 'bottomCenter',
  topCenter = 'topCenter',
  leftCenter = 'leftCenter',
  rightCenter = 'rightCenter',
}

export const MDS_DROPDOWN_POSITION_OFFSET = 5;
