import {InjectionToken} from '@angular/core';
import {LayoutService} from './layout.service';
import {PuiLayoutBreakpoints} from './layout.types';
export const PUI_LAYOUT = new InjectionToken<LayoutService>('PUI_LAYOUT', {
  providedIn: 'root',
  factory: (): LayoutService => new LayoutService(),
});

export const PUI_LAYOUT_BREAKPOINTS = new InjectionToken<PuiLayoutBreakpoints>('PUI_LAYOUT_BREAKPOINTS', {
  providedIn: 'root',
  factory: (): PuiLayoutBreakpoints => ({
    XSmall: '(max-width: 599.98px)',
    Small: '(min-width: 600px) and (max-width: 959.98px)',
    Medium: '(min-width: 960px) and (max-width: 1279.98px)',
    Large: '(min-width: 1280px) and (max-width: 1919.98px)',
    XLarge: '(min-width: 1920px)',
    Mobile:
      '(max-width: 599.98px) and (orientation: portrait), ' + '(max-width: 959.98px) and (orientation: landscape)',
    Tablet:
      '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), ' +
      '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
    Desktop: '(min-width: 840px) and (orientation: portrait), ' + '(min-width: 1280px) and (orientation: landscape)',
    MobilePortrait: '(max-width: 599.98px) and (orientation: portrait)',
    TabletPortrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
    DesktopPortrait: '(min-width: 840px) and (orientation: portrait)',
    MobileLandscape: '(max-width: 959.98px) and (orientation: landscape)',
    TabletLandscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
    DesktopLandscape: '(min-width: 1280px) and (orientation: landscape)',
  }),
});
