import {inject, Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {map, Observable} from 'rxjs';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {IBillingProduct} from '../entities/billing-product';
import {IProductsDataProvider} from '../entities/products-data-provider';

@Injectable()
export class ProductsDataService implements IProductsDataProvider {
  private readonly apollo = inject(Apollo);

  private readonly productsQuery = gql<
    {billingRecurringProductsForCustomerIp: IBillingProduct[]},
    {productFamily: string; ip?: string}
  >`
    query BillingRecurringProductsForCustomerIp($productFamily: ProductFamily, $ip: IpAddress) {
      billingRecurringProductsForCustomerIp(productFamily: $productFamily, ip: $ip) {
        id
        currencyCode
        name
        price
        billingProductFamily {
          id
        }
        product {
          id
          name
          metaData
        }
        period
        periodUnit
      }
    }
  `;

  getProducts(productFamily: ProductFamily, ip?: string): Observable<IBillingProduct[]> {
    return this.apollo
      .query({
        query: this.productsQuery,
        variables: {productFamily, ip},
      })
      .pipe(
        map(response => {
          if (response.data?.billingRecurringProductsForCustomerIp) {
            return response.data.billingRecurringProductsForCustomerIp.filter(
              item => item.product.metaData?.['experimental'] !== '1'
            );
          }
          throw new Error('cant get products');
        })
      );
  }
}
