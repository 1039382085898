import {Injectable} from '@angular/core';
import {Events} from '../enums/events';
import {TrackingService} from '@px/shared/user-tracking';

@Injectable()
export class PssThemesTrackingService {
  constructor(private readonly trackingService: TrackingService) {}

  async connect(noTracking: boolean): Promise<boolean> {
    if (noTracking) {
      return true;
    }

    try {
      await this.trackingService.init();
      this.trackingService.identify();
    } catch (e) {
      console.error(e);
      return false;
    }

    return true;
  }

  downLoadVideoByClient(isFromPlayer: boolean): void {
    this.trackingService.track(Events.CLIENT_DOWNLOAD_VIDEO, {
      Source: isFromPlayer ? 'Slideshow Player' : 'End Screen',
    });
  }
}
