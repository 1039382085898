<px-feature-page-mobile-not-supported
  redirectUrl="/"
  [redirectUrlText]="'Visit Slideshow Manager' | translate"
  [descriptions]="[
    'Editing slideshows is not supported on mobile devices' | translate,
    'LOGIN_PAGE.MOBILE_LOGIN_MESSAGE_PART_TWO' | translate
  ]"
  [companyUrl]="platformEnvironment.PIXELLU_URL"
>
  <ng-container ngProjectAs="logo">
    <i class="pss-icons_03-smartslides-icon icon-logo"></i>
  </ng-container>
</px-feature-page-mobile-not-supported>
