import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, filter, map, Observable, of, switchMap, take, tap, timer} from 'rxjs';
import {SubscriptionActivationState} from '@ps/pricing-domain';
import {ADDITIONAL_SUBSCRIPTION_ACTIVATION_CHECK} from '../tokens/additional-subscription-activation-check';

@Injectable()
export class SubscriptionActivationWaitingFacade {
  private additionalSubscriptionActivationCheck = inject(ADDITIONAL_SUBSCRIPTION_ACTIVATION_CHECK, {optional: true});
  private isWaitingShownInternal$ = new BehaviorSubject(false);
  private isWaitingInternal$ = new BehaviorSubject(false);

  isWaitingShown$ = this.isWaitingShownInternal$.asObservable();
  isWaiting$ = this.isWaitingInternal$.asObservable();

  private waitAdditionalSubscriptionActivation(productId?: string): Observable<void> {
    return timer(0, 2000).pipe(
      switchMap(() => this.additionalSubscriptionActivationCheck?.(<string>productId) ?? of(true)),
      filter(isActive => isActive),
      map(() => undefined),
      take(1)
    );
  }

  handleSubscriptionActivationState(subscriptionActivationState: SubscriptionActivationState): Observable<void> {
    switch (subscriptionActivationState.isActivating) {
      case true: {
        this.isWaitingInternal$.next(true);
        this.isWaitingShownInternal$.next(true);
        return EMPTY;
      }
      case false:
        return this.waitAdditionalSubscriptionActivation(subscriptionActivationState.billingProduct?.productId).pipe(
          tap(() => {
            this.isWaitingInternal$.next(false);
          })
        );
      case null:
      default:
        return EMPTY;
    }
  }

  reset(): void {
    this.isWaitingInternal$.next(false);
    this.isWaitingShownInternal$.next(false);
  }
}
