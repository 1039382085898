import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';

export interface IProgressData {
  total: number;
  uploaded: number;
}

@Pipe({
  name: 'progressBytes',
})
export class ProgressBytesPipe implements PipeTransform {
  private kb = 1000;
  private measureBytes = {
    mb: {
      divisor: Math.pow(this.kb, 2),
      name: 'MB',
    },
    gb: {
      divisor: Math.pow(this.kb, 3),
      name: 'GB',
    },
  };

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(progressData: IProgressData): string {
    const total = progressData ? progressData.total : 0;
    const uploaded = progressData ? progressData.uploaded : 0;
    const sizeUnit = this.measureBytes[total < this.measureBytes.gb.divisor ? 'mb' : 'gb'];
    return (
      formatNumber(uploaded / sizeUnit.divisor, this.locale, '1.0-0') +
      '/' +
      formatNumber(total / sizeUnit.divisor, this.locale, '1.0-0') +
      ' ' +
      sizeUnit.name
    );
  }
}
