import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {IMdsDropdownPanel} from './dropdown.interfaces';
import {MDS_DROPDOWN_POSITION, MDS_DROPDOWN_TYPE} from './dropdown.costants';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgClass} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

@Component({
  selector: 'mds-dropdown',
  standalone: true,
  imports: [NgClass, A11yModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationTransition', [
      state('void', style({opacity: 0, transform: 'scale(0.95)'})),
      state('enter', style({opacity: 1, transform: 'scale(1)'})),
      state('leave', style({opacity: 0, transform: 'scale(0.95)'})),
      transition('* => *', animate('150ms')),
    ]),
  ],
})
export class MdsDropdownComponent<T = void> implements IMdsDropdownPanel<T> {
  @ViewChild(TemplateRef) templateRef?: TemplateRef<ElementRef>;

  @Output() close$ = new EventEmitter<void>();

  @Input() type: MDS_DROPDOWN_TYPE = MDS_DROPDOWN_TYPE.base;
  @Input() position: MDS_DROPDOWN_POSITION = MDS_DROPDOWN_POSITION.base;

  MDS_DROPDOWN_TYPE = MDS_DROPDOWN_TYPE;
  transformOrigin = '';
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  hostClass: string[] = [];
  context?: T;

  close = (): void => {
    this.close$.emit();
  };
}
