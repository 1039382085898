export interface IPhotoPic {
  id: number;
}

export interface IPhoto {
  mediaItemId: string;
}

export function isPhotoPic(photo: IPhotoPic | IPhoto): photo is IPhotoPic {
  return (photo as IPhotoPic).id !== undefined;
}

export function isPhoto(photo: IPhotoPic | IPhoto): photo is IPhoto {
  return (photo as IPhoto).mediaItemId !== undefined;
}
