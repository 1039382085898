import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'mds-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  @Input() color = '#73AF55';
}
