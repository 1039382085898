import {inject, Injectable} from '@angular/core';
import {CustomerSubscriptionsDataService} from '../infrastructure/customer-subscriptions.data.service';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {ICustomerSubscription} from '../entities/customer-subscription';
import {SubscriptionStatus} from '../enums/subscription-status';
import {filter, identity, map, Observable, switchMap, take, timeout, timer} from 'rxjs';
import {ICustomerSubscriptionsFacade} from '../entities/customer-subscriptions-facade.interface';
import {JwtWrapperService} from '../infrastructure/jwt-wrapper.service';

@Injectable()
export class CustomerSubscriptionsFacadeService implements ICustomerSubscriptionsFacade {
  private readonly customerSubscriptionsDataService = inject(CustomerSubscriptionsDataService);
  private readonly jwtWrapperService = inject(JwtWrapperService);

  getCustomerActiveSubscriptions(
    productFamily?: ProductFamily,
    customerId?: string
  ): Observable<ICustomerSubscription[]> {
    return this.jwtWrapperService.wrap(token =>
      this.customerSubscriptionsDataService.getCustomerSubscriptions(
        customerId,
        productFamily,
        SubscriptionStatus.ACTIVE,
        token
      )
    );
  }

  updateCustomerActiveSubscription(subscriptionId: string, priceId: string): Observable<boolean> {
    return this.jwtWrapperService.wrap(token =>
      this.customerSubscriptionsDataService.updateCustomerSubscription(subscriptionId, priceId, token)
    );
  }

  cancelCustomerSubscription(subscriptionId: string): Observable<boolean> {
    return this.jwtWrapperService.wrap(token =>
      this.customerSubscriptionsDataService.cancelCustomerSubscription(subscriptionId, token)
    );
  }

  /**
   * Checks if the user has only one subscription
   *
   * @param {string} priceId
   * @param {string} [productFamily] - if there is no productFamily, it checks all subscriptions of the user
   * @param {string} [customerId]
   * @return {Observable<boolean>}
   *
   * @example
   *
   *     billingProductIsOnlyActiveSubscription('priceId', ProductFamily.PSS) - checks if the user's subscription is the only one within the "productFamily"
   */
  billingProductIsOnlyActiveSubscription(
    priceId: string,
    productFamily?: ProductFamily,
    customerId?: string
  ): Observable<boolean> {
    return this.getCustomerActiveSubscriptions(productFamily, customerId).pipe(
      map(subscriptions => !!subscriptions[0]?.package.products.find(p => p.price?.id === priceId))
    );
  }

  waitForOnlyActivatedSubscription(
    priceId: string,
    productFamily?: ProductFamily,
    pollingIntervalMs = 1000,
    timeOutMs?: number,
    customerId?: string
  ): Observable<void> {
    return timer(0, pollingIntervalMs).pipe(
      switchMap(() => this.billingProductIsOnlyActiveSubscription(priceId, productFamily, customerId)),
      filter(isActive => isActive),
      map(() => undefined),
      take(1),
      timeOutMs ? timeout(timeOutMs) : identity
    );
  }
}
