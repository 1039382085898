import {ProductFamily} from '@px/shared/data-access/product-product-family';

type IProduct = {
  price?: {
    id: string;
    name: string;
    billingProductFamily: {
      productFamily: ProductFamily;
    };
    product: {
      id: string;
      name: string;
      metaData: Record<string, unknown>;
    };
    metaData: Record<string, unknown>;
  };
};

type ICustomerCard = {
  brand: string;
};

export interface ICustomerSubscription {
  id: string;
  nextBillingDate: number;
  currentPeriodEnd: string;
  package: {
    products: IProduct[];
  };
  customer: {
    paymentSourceCards: ICustomerCard[] | null;
  };
}
