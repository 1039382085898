import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {map, timer} from 'rxjs';
import {SUBSCRIPTION_MANAGER_CONFIG} from '../tokens/subscription-manager-config';
import {SHARED_EVENT_BUS_SERVICE} from '@px/shared/event-bus';
import {SubscriptionEventNames} from '@ps/pricing-domain';

enum BodyView {
  WAITING,
  FINISHED,
  TIMEOUT,
}

@Component({
  selector: 'px-subscription-activation-waiting-dialog',
  templateUrl: './subscription-activation-waiting-dialog.component.html',
  styleUrls: ['./subscription-activation-waiting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeIn', [state('void', style({opacity: 0})), transition(':enter', [animate(700)])])],
})
export class SubscriptionActivationWaitingDialogComponent {
  private readonly subscriptionManagerConfig = inject(SUBSCRIPTION_MANAGER_CONFIG);
  private readonly eventBus = inject(SHARED_EVENT_BUS_SERVICE);
  private readonly cdr = inject(ChangeDetectorRef);

  private isWaitingSubscriptionInternal = false;

  @Output() subscriptionActivatedActionClick$ = new EventEmitter<void>();

  passedMaxWaitingTime$ = timer(this.subscriptionManagerConfig.activationWaitingTimeout).pipe(map(() => true));

  BodyView = BodyView;

  get bodyView(): BodyView {
    return this.isWaitingSubscription ? BodyView.WAITING : BodyView.FINISHED;
  }

  get subscriptionActivatedActionBtnText(): string {
    return this.subscriptionManagerConfig.subscriptionActivatedActionBtnText ?? '';
  }

  get activationAdditionalSuccessText(): string {
    return this.subscriptionManagerConfig.activationAdditionalSuccessText ?? '';
  }

  get isWaitingSubscription(): boolean {
    return this.isWaitingSubscriptionInternal;
  }

  @Input()
  set isWaitingSubscription(value: boolean) {
    this.isWaitingSubscriptionInternal = value;
    this.cdr.detectChanges();
  }

  handleActionClick(): void {
    this.subscriptionActivatedActionClick$.emit();
    this.eventBus.emit({key: SubscriptionEventNames.SUBSCRIPTION_ACTIVATED_ACTION_CLICK});
  }
}
