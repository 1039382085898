import {InjectionToken} from '@angular/core';
import {IMdsDialogService} from '../services/dialog-service.interface';
import {MdsDialogService} from '../services/dialog.service';
import {IMdsDialogConfig} from './dialog-config.interface';

export const MDS_DIALOG_SERVICE = new InjectionToken<IMdsDialogService>('MdsDialogService', {
  providedIn: 'root',
  factory: (): IMdsDialogService => new MdsDialogService(),
});

export const MDS_DIALOG_PROVIDER = {
  provide: MDS_DIALOG_SERVICE,
  useClass: MdsDialogService,
};

export const MDS_DIALOG_DATA = new InjectionToken<IMdsDialogConfig>('MdsDialogConfig');
