import {inject, Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {ICroppingData} from '../entities/cropping-data';
import {CroppingDataGqlService} from '../infrastructure/cropping-data-gql.service';
import {ImageCroppingRatio} from '../enums/image-cropping-ratio';
import {IPhotoCroppingGql} from '../entities/photo-cropping-gql';
import {IPhoto, IPhotoPic, isPhoto, isPhotoPic} from '../entities/photo.interface';

@Injectable({
  providedIn: 'root',
})
export class CroppingFacadeService {
  private readonly croppingDataGQLService = inject(CroppingDataGqlService);
  readonly CROP_QUERY_PARAM_KEY = 'crop';

  getPhotoCroppingData$(photos: (IPhotoPic | IPhoto)[], ratio: ImageCroppingRatio): Observable<IPhotoCroppingGql[]> {
    const picPhotos = photos.filter(isPhotoPic);
    const gqlPhotos = photos.filter(isPhoto);

    // assume that photos can be only PIC or only GQL, not both
    if (picPhotos.length) {
      return this.croppingDataGQLService
        .getImageCroppingDataByPicId$(
          picPhotos.map(p => p.id),
          ratio
        )
        .pipe(map(data => data.map(({croppingData, picId}) => ({id: picId, croppingData}))));
    }

    if (gqlPhotos.length) {
      return this.croppingDataGQLService.getImageCroppingData$(
        gqlPhotos.map(p => p.mediaItemId),
        ratio
      );
    }

    console.warn('No photos to crop');
    return of([]);
  }

  createCropParameter({x, y, width, height}: ICroppingData): string {
    return `${this.CROP_QUERY_PARAM_KEY}=${[x, y, width, height].join(',')}`;
  }
}
