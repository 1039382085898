export function pxOrStringValueAttribute(value: number | string): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  const number = parseFloat(value);
  if (!isNaN(number)) {
    return `${number}px`;
  }

  return value.includes('px') ? value.slice(0, value.indexOf('px') + 2) : value;
}
