<div class="pricing">
  <div *ngIf="showPeriodSelector" class="pricing__period-selector">
    <px-pricing-period-selector
      [disabled]="isLoading || !!buyingProduct"
      [value]="period"
      (valueChange)="period$.next($event)"
    />
    <span *ngIf="showPricingYearly" class="pricing__promo">{{ 'Save 33% with yearly billing' | translate }}</span>
  </div>

  <div class="pricing__plans" *ngIf="!isLoading; else skeleton">
    <ng-container *ngFor="let billingProduct of billingProductsByPeriod$ | async; trackBy: trackBy">
      <px-pricing-plan-card
        [class.highlighted]="isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY"
        [title]="billingProduct.name"
        [priceMonthly]="billingProduct.priceMonthly"
        [priceYearly]="billingProduct.priceYearly"
        [newPrice]="isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY"
        [newPriceMonthly]="getSale10Price(billingProduct.priceMonthly)"
        [newPriceYearly]="getSale10Price(billingProduct.priceYearly)"
        [features]="billingProduct.features"
        [promoTitle]="
          isSale10(billingProduct.productFamily) && period === BillingPeriod.YEARLY
            ? ('Save 30% for 1 year' | translate)
            : (billingProduct.promo ?? '' | translate)
        "
        [highlighted]="!!billingProduct.promo"
        [currencyCode]="billingProduct.currencyCode"
        [actionBlocked]="!!buyingProduct"
        [actionDisabled]="disableUnavailablePlans && !!activeBundleSubscription?.id"
        [actionPerformed]="buyingProduct?.id === billingProduct.id"
        [subscribed]="isProductSubscribed(billingProduct.id)"
        [actionLabel]="getActionLabel(billingProduct.productId) | translate | titlecase"
        (buy$)="buy(billingProduct)"
      />
    </ng-container>
  </div>

  <div class="pricing__note">
    <ng-container *ngIf="(currencyCode$ | async) === null; else currenciesLoadedTmpl">
      <mds-skeleton-bone width="242px" height="14px" />
    </ng-container>

    <ng-template #currenciesLoadedTmpl>
      {{ 'All prices are in [currencyCode]' | translate: {currencyCode: currencyCode$ | async} }}

      <span *ngIf="showPricingYearly">, {{ 'Billed yearly' | translate | lowercase }} </span>
    </ng-template>
  </div>

  <ng-template #skeleton>
    <mds-skeleton isAnimated>
      <div class="plans-skeleton">
        <div class="plans-skeleton__item" *ngFor="let skeleton of skeletons; trackBy: utilTrackByIndex">
          <div class="plans-skeleton__card">
            <mds-skeleton-bone class="plans-skeleton__bone plans-skeleton__title" />
            <div class="plans-skeleton__price">
              <mds-skeleton-bone width="70px" height="32px" />
            </div>
            <div class="plans-skeleton__features">
              <div class="plans-skeleton__feature">
                <mds-skeleton-bone width="120px" height="14px" />
              </div>
              <div class="plans-skeleton__feature">
                <mds-skeleton-bone width="90px" height="14px" />
              </div>
              <div class="plans-skeleton__feature">
                <mds-skeleton-bone width="105px" height="14px" />
              </div>
            </div>
            <mds-skeleton-bone class="plans-skeleton__action">
              <div class="plans-skeleton__action-button">
                <mds-button>
                  {{ 'Buy now' | translate | titlecase }}
                </mds-button>
              </div>
            </mds-skeleton-bone>
          </div>
        </div>
      </div>
    </mds-skeleton>
  </ng-template>
</div>
