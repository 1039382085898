import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutService} from './layout.service';
import {MDS_LAYOUT_SERVICE_TOKEN} from './layout.tokens';

@NgModule({
  imports: [CommonModule],
  providers: [{provide: MDS_LAYOUT_SERVICE_TOKEN, useClass: LayoutService}],
})
export class MdsLayoutModule {}
