<div class="subscription-activation-waiting">
  <div class="subscription-activation-waiting__header">
    <div class="subscription-activation-waiting__logo">
      <mds-icon icon="caPixelluLogo" />
    </div>
  </div>

  <ng-container [ngSwitch]="bodyView">
    <div class="subscription-activation-waiting__body" *ngSwitchCase="BodyView.WAITING" @fadeIn>
      <div class="subscription-activation-waiting__title">{{ 'Processing upgrade' | translate }}...</div>

      <div class="subscription-activation-waiting__loader" *ngIf="isWaitingSubscription">
        <mds-spinner diameter="24" />
      </div>

      <div class="subscription-activation-waiting__text">
        <ng-container *ngIf="passedMaxWaitingTime$ | async; else waitingUntilMaxTimeTmpl">
          {{ 'Your request is still being processed and may take a few more moments' | translate }}. <br /><br />
          {{ 'Thank you for your patience' | translate }}.
        </ng-container>
        <ng-template #waitingUntilMaxTimeTmpl>
          {{ 'It may be several minutes for the processing of your upgrade to complete' | translate }}.
        </ng-template>
      </div>
    </div>

    <div class="subscription-activation-waiting__body" *ngSwitchCase="BodyView.FINISHED" @fadeIn>
      <mds-confirmation class="subscription-activation-waiting__check" />

      <div class="subscription-activation-waiting__title subscription-activation-waiting__title--finished">
        {{ 'Upgrade is complete' | translate }}
      </div>

      <div class="subscription-activation-waiting__text">
        {{ 'Thank you for your purchase' | translate }}
      </div>

      <div *ngIf="activationAdditionalSuccessText" class="subscription-activation-waiting__text">
        {{ activationAdditionalSuccessText }}
      </div>

      <div class="subscription-activation-waiting__actions">
        <button (click)="handleActionClick()">
          {{ subscriptionActivatedActionBtnText }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
