<div class="mobile-page-wrapper" layout="column" layout-align="center center">
  <div class="logo-wrapper">
    <a [routerLink]="redirectUrl" class="icon-logo"><ng-content select="logo"></ng-content></a>
  </div>
  <div class="center-container text-center" layout="column" layout-align="center center">
    <div *ngFor="let description of descriptions" class="text-line">{{ description }}</div>
    <div class="link-separator"></div>
    <a *ngIf="redirectUrlText" class="px-link" [routerLink]="redirectUrl">{{ redirectUrlText }}</a>
  </div>
  <div>
    <a [href]="companyUrl" style="display: inline-block; color: #373939">
      <mds-icon icon="caPixelluLogo" />
    </a>
  </div>
</div>
