import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from '@px/shared/env';
import {Observable} from 'rxjs';

@Injectable()
export class TranslationService {
  constructor(private translate: TranslateService) {}

  init(): void {
    this.translate.addLangs([Languages.en, Languages.pt, Languages.ru, Languages.uk, Languages.it, Languages.de]);
    this.translate.setDefaultLang(Languages.en);
  }

  change(newLang?: string): Observable<void> {
    const languages = this.translate.getLangs();
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = languages.includes(browserLang) ? browserLang : Languages.en;

    return this.translate.use(languages.includes(newLang) ? newLang : defaultLang);
  }
}
