import {IBillingProductClientFeature} from './billing-product-client-feature';
import {BillingPeriod} from '../enums/billing-period';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface IBillingProductClient {
  id: string;
  name: string; // NOTE: translated product name
  productName: string; // NOTE: raw product name
  priceMonthly: number;
  priceYearly?: number;
  period: BillingPeriod;
  productFamily: ProductFamily;
  features: IBillingProductClientFeature[];
  currencyCode: string;
  productId: string;
  promo: string | null;
}
