import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Clipboard} from '@angular/cdk/clipboard';

@Injectable()
export class UtilsService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private readonly clipboard: Clipboard
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private animationCopy(animText: string, rect: DOMRect): void {
    if (!animText && !rect) {
      return;
    }
    const span = this.renderer.createElement('span');
    const text = this.renderer.createText(animText);

    this.renderer.setStyle(span, 'top', `${rect.top - 13}px`);
    this.renderer.setStyle(span, 'left', `${rect.left + rect.width / 2}px`);
    this.renderer.appendChild(span, text);
    this.renderer.addClass(span, 'copied');
    this.renderer.appendChild(this.document.body, span);

    window.setTimeout(() => {
      this.renderer.addClass(span, 'up');
    }, 10);

    setTimeout(() => {
      this.renderer.removeChild(this.document.body, span);
    }, 320);
  }

  /**
   * @deprecated use angular/cdk/clipboard instead
   */
  copyToClipboard(text: string, animText?: string, $event?: MouseEvent): void {
    this.clipboard.copy(text);
    if (animText) {
      const rect = $event?.currentTarget && ($event.currentTarget as HTMLElement).getBoundingClientRect();
      this.animationCopy(animText, rect);
    }
  }
}
