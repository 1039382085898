<div class="plan-feature">
  <span
    *ngFor="let part of feature?.parts; trackBy: trackBy"
    class="plan-feature-part"
    [ngClass]="{'plan-feature-part--with-tooltip': !!part.hint?.length}"
    [puiTooltip]="tooltipTmpl"
    [puiTooltipDisabled]="!part.hint?.length"
    [puiTooltipShowDelay]="10"
  >
    {{ part.text }}
    <ng-template #tooltipTmpl>
      <ul class="plan-feature__tooltip" *ngIf="part.hint?.length">
        <li *ngFor="let feature of part.hint">{{ feature }}</li>
      </ul>
    </ng-template>
  </span>
</div>
