import {IApiResponse, IAuthService, TransitionTypeId} from '@px/shared/api';
import {Observable} from 'rxjs';

export interface IPSSAuthService extends IAuthService {
  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<IApiResponse>;
}

export interface ISaveLastTransitionPicInput {
  transition_id: TransitionTypeId;
  transition_duration: number;
}
