export type SessionCreationErrors = SessionCreationForbidden | UnknownSessionCreationError;

export class SessionCreationForbidden extends Error {
  override name = 'SessionCreationForbidden';
  override message = 'checkout session creation forbidden';
}

export class UnknownSessionCreationError extends Error {
  override name = 'UnknownSessionCreationError';
  override message = 'unknown checkout creation error';
}

export class SessionCreationTimeout extends Error {
  override name = 'SessionCreationTimeout';
  override message = 'Valid JWT token was not fed for creation session in a time.';
}

export class SessionCreationAttemptsExpired extends Error {
  override name = 'SessionCreationAttemptsExpired';

  constructor(private readonly attempts: number) {
    super();
    this.message = `Cant create session after ${this.attempts} attempts`;
  }
}
