import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(public router: Router) {}

  init(TRACKING_ID: string): void {
    try {
      gtag('config', TRACKING_ID, {send_page_view: false});
      this.router.events.subscribe(event => {
        try {
          if (typeof gtag === 'function') {
            if (event instanceof NavigationEnd) {
              gtag('config', TRACKING_ID, {page_path: event.urlAfterRedirects});
            }
          }
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
