import {inject, Injectable} from '@angular/core';
import {IAuthRepository} from '@px/pss/auth';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app/app.state';
import {LogOut} from '../store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthRepositoryService implements IAuthRepository {
  private readonly store$ = inject(Store<AppState>);

  logout(): void {
    this.store$.dispatch(LogOut());
  }
}
