import {NgModule} from '@angular/core';
import {MdsDropdownComponent} from './dropdown.component';
import {MdsDropdownTriggerForDirective} from './dropdown-trigger-for.directive';

/**
 * @deprecated Use standalone components instead
 */
@NgModule({
  imports: [MdsDropdownComponent, MdsDropdownTriggerForDirective],
  exports: [MdsDropdownComponent, MdsDropdownTriggerForDirective],
})
export class MdsDropdownModule {}
