import {ICustomerSubscription} from '../entities/customer-subscription';
import {Observable} from 'rxjs';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface ICustomerSubscriptionsFacade {
  getCustomerActiveSubscriptions(
    productFamily: ProductFamily,
    customerId?: string
  ): Observable<ICustomerSubscription[]>;
  updateCustomerActiveSubscription(subscriptionId: string, priceId: string): Observable<boolean>;
  cancelCustomerSubscription(subscriptionId: string): Observable<boolean>;
  billingProductIsOnlyActiveSubscription(
    priceId: string,
    productFamily?: ProductFamily,
    customerId?: string
  ): Observable<boolean>;
  waitForOnlyActivatedSubscription(
    priceId: string,
    productFamily?: ProductFamily,
    pollingIntervalMs?: number,
    timeOutMs?: number,
    customerId?: string
  ): Observable<void>;
}
