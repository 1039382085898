import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/auth.effects';
import {authReducers} from './store/auth.reducer';
import {AUTH_REPOSITORY_TOKEN} from '@px/pss/auth';
import {AuthRepositoryService} from './services/auth-repository.service';

@NgModule({
  imports: [StoreModule.forFeature('auth', authReducers), EffectsModule.forFeature([AuthEffects])],
  providers: [{provide: AUTH_REPOSITORY_TOKEN, useClass: AuthRepositoryService}],
})
export class AuthModule {}
