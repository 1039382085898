import {Action} from '@ngrx/store';

export enum GlobalActionTypes {
  CLOSE_CONFIRM_DIALOG = '[Globals] Close Confirm Dialog',
  CONNECTION_OFFLINE = '[Globals] Connection Offline',
  CONNECTION_ONLINE = '[Globals] Connection Online',
  OFFLINE_SNACKBAR_HIDE = '[Globals] Offline Snackbar Hide',
  OFFLINE_SNACKBAR_SHOW = '[Globals] Offline Snackbar Show',
  REDIRECT_SUPPORTED_BROWSER = '[Globals] Redirect Supported Browser',
  REDIRECT_UNSUPPORTED_BROWSER = '[Globals] Redirect Unsupported Browser',
  REDIRECT_DESKTOP_DEVICE = '[Globals] Redirect Desctop Device',
  REDIRECT_MOBILE_DEVICE = '[Globals] Redirect Mobile Device',
  DOWNLOAD_FILE = '[Globals] Download File',
}

export class CloseConfirmDialog implements Action {
  readonly type = GlobalActionTypes.CLOSE_CONFIRM_DIALOG;
}

export class ConnectionOffline implements Action {
  readonly type = GlobalActionTypes.CONNECTION_OFFLINE;
}

export class ConnectionOnline implements Action {
  readonly type = GlobalActionTypes.CONNECTION_ONLINE;
}

export class OfflineSnackbarHide implements Action {
  readonly type = GlobalActionTypes.OFFLINE_SNACKBAR_HIDE;
}

export class OfflineSnackbarShow implements Action {
  readonly type = GlobalActionTypes.OFFLINE_SNACKBAR_SHOW;

  constructor(public payload: any) {}
}

export class RedirectSupportedBrowser implements Action {
  readonly type = GlobalActionTypes.REDIRECT_SUPPORTED_BROWSER;
}

export class RedirectUnsupportedBrowser implements Action {
  readonly type = GlobalActionTypes.REDIRECT_UNSUPPORTED_BROWSER;
}

export class RedirectDesktopDevice implements Action {
  readonly type = GlobalActionTypes.REDIRECT_DESKTOP_DEVICE;
}

export class RedirectMobileDevice implements Action {
  readonly type = GlobalActionTypes.REDIRECT_MOBILE_DEVICE;
}

export class DownloadFile implements Action {
  readonly type = GlobalActionTypes.DOWNLOAD_FILE;

  constructor(public url: string) {}
}

export type GlobalActionsUnion =
  | CloseConfirmDialog
  | ConnectionOffline
  | ConnectionOnline
  | OfflineSnackbarHide
  | OfflineSnackbarShow
  | RedirectSupportedBrowser
  | RedirectUnsupportedBrowser
  | RedirectDesktopDevice
  | RedirectMobileDevice
  | DownloadFile;
