<div class="plan-card" [ngClass]="{'plan-card--highlighted': highlighted}">
  <span *ngIf="promoTitle" class="plan-card__promo-title">{{ promoTitle }}</span>
  <div class="plan-card__body">
    <h3 class="plan-card__title">{{ title }}</h3>
    <div
      class="plan-card__price price"
      *ngIf="{
        monthly: priceMonthly | currency: currencyCode : 'symbol-narrow' : '1.0-0'
      } as prices"
    >
      <ng-container *ngIf="!newPrice">
        <p *ngIf="prices.monthly" class="price__monthly">
          <span class="price__value">
            <span class="price__currency">
              <span class="price__symbol">{{ prices.monthly[0] }}</span
              >{{ prices.monthly.slice(1) }}</span
            >
            <span>/{{ 'mo' | translate }}</span>
          </span>
        </p>
        <p *ngIf="priceYearly" class="price__yearly">
          <span class="price__value">
            <span>{{ priceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}/{{ 'year' | translate }}</span>
          </span>
        </p>
      </ng-container>
      <ng-container *ngIf="newPrice">
        <p *ngIf="prices.monthly" class="price__monthly">
          <span class="price__value price__value--old">
            <span class="price__currency">
              <span class="price__symbol">
                {{ prices.monthly[0] }}
              </span>
              {{ prices.monthly.slice(1) }}
            </span>
          </span>
          <span class="price__value price__value--new">
            <span class="price__currency">
              <span class="price__symbol"></span>
              {{ newPriceMonthly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}
            </span>
            <span>/{{ 'mo' | translate }}</span>
          </span>
        </p>
        <p *ngIf="priceYearly" class="price__yearly">
          <span class="price__value" [ngClass]="{'price__value--old': newPrice}">
            <span>{{ priceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}</span>
          </span>
          <span class="price__value price__value--new">
            <span>
              {{ newPriceYearly | currency: currencyCode : 'symbol-narrow' : '1.0-0' }}/{{ 'year' | translate }}
            </span>
          </span>
        </p>
      </ng-container>
    </div>
    <div class="plan-card__features">
      <px-plan-feature *ngFor="let feature of features; trackBy: trackBy" [feature]="feature" />
    </div>
    <div class="plan-card__action">
      <div class="plan-card__subscribed-label" *ngIf="subscribed; else buyAction">
        {{ 'Subscribed' | translate }}
      </div>
      <ng-template #buyAction>
        <mds-button
          [hasProgress]="actionPerformed"
          [readonly]="actionBlocked"
          [type]="highlighted ? 'filled' : 'outlined'"
          [color]="highlighted ? 'primary' : 'secondary'"
          [disabled]="actionDisabled"
          (click$)="buy()"
        >
          {{ actionLabel }}
        </mds-button>
      </ng-template>
    </div>
  </div>
</div>
