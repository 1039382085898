export class JWTWrapperAttemptsExpiredError extends Error {
  override name = 'JWTWrapperAttemptsExpiredError';

  constructor(private readonly attempts: number) {
    super();
    this.message = `Cant call a function with JWT after ${this.attempts} attempts`;
  }
}

export class JWTWaitingTimeout extends Error {
  override name = 'JWTWrappingTimeout';
  override message = 'Valid JWT token was not fed for JWT wrapping in a time.';
}
