import {IEventBusService} from './event-bus-service.interface';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {IBusEvent} from './bus-event';
import {Injectable} from '@angular/core';

@Injectable()
export class EventBusService implements IEventBusService {
  private eventBus = new Subject<IBusEvent>();

  on<T>(key: string): Observable<T> {
    return this.eventBus.pipe(
      filter(event => event.key === key),
      map(event => event.data as T)
    );
  }

  emit<T>(event: IBusEvent<T>): void {
    this.eventBus.next(event);
  }
}
