import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {ReminderStatus} from '@px/shared/api';

@Component({
  selector: 'px-logo-website-reminder',
  templateUrl: './logo-website-reminder.component.html',
  styleUrls: ['./logo-website-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'true',
        style({
          height: '50px',
          opacity: 1,
        })
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      transition('true => false', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class LogoWebsiteReminderComponent {
  isOpen = true;

  @Input() hasLogo?: boolean;
  @Input() hasWebsite?: boolean;

  @Output() close$ = new EventEmitter<ReminderStatus>();

  dismiss(): void {
    this.isOpen = false;
  }

  onAnimationEvent(event: AnimationEvent): void {
    if (event.fromState === 'true' && event.toState === 'false') {
      this.close$.emit(ReminderStatus.CLOSED_BY_USER);
    }
  }
}
