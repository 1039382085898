<button class="sort-btn" mat-flat-button [matMenuTriggerFor]="sortMenu" aria-label="Example icon-button with a menu">
  <i class="pss-icons_66-sort"></i>
</button>
<mat-menu #sortMenu="matMenu" class="manager-sort-menu">
  <button
    mat-menu-item
    *ngFor="let sortType of list | keyvalue: originalOrder"
    (click)="sortSelect(sortType.key)"
    [class.selected]="currentSortType === sortType.key"
  >
    {{ sortType.value.title | translate }}
  </button>
</mat-menu>
