import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {filter} from 'rxjs/operators';
import {GuardsCheckEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ISurveyResult} from '../../interfaces/survey-result.interface';
import {IQuestion} from '@px/shared/api';

@UntilDestroy()
@Component({
  selector: 'px-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SurveyDialogComponent {
  @Input() questions: (IQuestion & {optionsIds: number[]; selectedOptionId: number})[] = [];
  @Input() isSaving = false;
  @Input() currentQuestionIndex = 0;
  @Input() maxQuestionIndex = 0;

  @Output() save$ = new EventEmitter<void>();
  @Output() optionChange$ = new EventEmitter<ISurveyResult>();
  @Output() surveySelect$ = new EventEmitter<number>();
  @Output() surveyStart$ = new EventEmitter<void>();
  @Output() next$ = new EventEmitter<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<SurveyDialogComponent>,
    private readonly router: Router,
    readonly cdr: ChangeDetectorRef
  ) {
    // We should not close dialog if user click back button (return to login page)
    this.router.events
      .pipe(
        filter(event => event instanceof GuardsCheckEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.dialogRef.close('GuardsCheckEnd');
      });
  }
}
