import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'ampLine',
  standalone: true,
})
export class AmpLinePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, prefix?: string): SafeHtml {
    if (!value) {
      return value;
    }

    prefix = prefix ? prefix + '-' : '';
    const attribute = `style="display: var(--${prefix}amp-line-display, inline); transform: var(--${prefix}amp-line-transform, scale(0.7) translateY(0))"`;

    if (value.includes('&')) {
      value = value.replace(/&(?!#)/g, `<span ${attribute}>&amp;</span>`);
    }
    if (value.toLowerCase().includes(' and ')) {
      value = value.replace(/ and (?!#)/g, `<span ${attribute}> and </span>`);
    }
    if (value.includes('+')) {
      value = value.replace(/\+/g, `<span ${attribute}>+</span>`);
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
