import {Injectable} from '@angular/core';
import dayjs from 'dayjs';

export enum SortDirections {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

@Injectable({
  providedIn: 'root',
})
export class NaturalSortService {
  private readonly natDateMonthFirst = new Intl.DateTimeFormat().format(new Date()).indexOf('12') === 0;

  private padding(value): string {
    return '00000000000000000000'.slice(value.length);
  }

  // Converts a value to a string.  Null and undefined are converted to ''
  private toString(value): string {
    return value === null || value === undefined ? '~~~' : '' + value;
  }

  private fixDates(value): string {
    // first look for dd?-dd?-dddd, where "-" can be one of "-", "/", or "."
    return this.toString(value).replace(/(\d\d?)[-\/\.](\d\d?)[-\/\.](\d{4})/, ($0, $m, $d, $y) => {
      // temporary holder for swapping below
      const t = $d;
      // if the month is not first, we'll swap month and day...
      if (!this.natDateMonthFirst) {
        // ...but only if the day value is under 13.
        if (Number($d) < 13) {
          $d = $m;
          $m = t;
        }
      } else if (Number($m) > 12) {
        // Otherwise, we might still swap the values if the month value is currently over 12.
        $d = $m;
        $m = t;
      }
      // return a standardized format.
      return $y + '-' + $m + '-' + $d;
    });
  }

  // Fix numbers to be correctly padded
  private fixNumbers(value): string {
    // First, look for anything in the form of d.d or d.d.d...
    return value.replace(/(\d+)((\.\d+)+)?/g, ($0, integer, decimal, $3) => {
      // If there's more than 2 sets of numbers...
      if (decimal !== $3) {
        // treat as a series of integers, like versioning,
        // rather than a decimal
        return $0.replace(/(\d+)/g, $d => this.padding($d) + $d);
      } else {
        // add a decimal if necessary to ensure decimal sorting
        decimal = decimal || '.0';
        return this.padding(integer) + integer + decimal + this.padding(decimal);
      }
    });
  }

  // Finally, this function puts it all together.
  private natValue(value): string {
    return this.fixNumbers(this.fixDates(typeof value === 'string' ? value.toLowerCase() : value));
  }

  sort(a, b, direction = SortDirections.ASCENDING): number {
    a = a instanceof Date ? dayjs(a).unix() : a;
    b = b instanceof Date ? dayjs(b).unix() : b;

    a = typeof a === 'number' ? a.toString() : a || '';
    b = typeof b === 'number' ? b.toString() : b || '';
    const aN = this.natValue(a);
    const bN = this.natValue(b);

    return (direction === SortDirections.ASCENDING ? 1 : -1) * (aN === bN ? b.localeCompare(a) : aN.localeCompare(bN));
  }
}
