import {Inject, Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {NavigationEnd, Router} from '@angular/router';
import {PSSPlatformEnvironment} from '../../platform-environment';
import {IEventBusService, SHARED_EVENT_BUS_SERVICE} from '@px/shared/event-bus';

declare let Beacon: (action: string, payload?: Record<string, string> | string, cb?: () => void) => void;

@Injectable({
  providedIn: 'root',
})
export class BeaconService {
  constructor(
    public router: Router,
    private readonly platform: PSSPlatformEnvironment,
    @Inject(SHARED_EVENT_BUS_SERVICE) private readonly eventBus: IEventBusService
  ) {}

  private static fixBeaconHeaderHeight(): void {
    try {
      const beaconFrame: HTMLIFrameElement = document.querySelector('#beacon-container .BeaconContainer > iframe');
      if (beaconFrame) {
        const beaconLayout__Header: HTMLElement = beaconFrame.contentWindow.document.querySelector(
          '.BeaconLayout__Header'
        ) as HTMLElement;
        if (beaconLayout__Header) {
          const height = parseInt(beaconLayout__Header.style.height);
          if (!height) {
            beaconLayout__Header.style.height = 'auto';
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  init(user: User): void {
    if (this.platform.BEACON_ENABLED) {
      Beacon('init', this.platform.BEACON_ID);
      Beacon('identify', {
        name: user.full_name,
        email: user.email,
        signature: user.beacon_signature,
      });
      Beacon('navigate', '/');
      Beacon('on', 'open', () => BeaconService.fixBeaconHeaderHeight());

      this.router.events.subscribe(event => {
        try {
          if (typeof Beacon === 'function') {
            if (event instanceof NavigationEnd) {
              Beacon('event', {
                type: 'page-viewed',
                url: document.location.href,
                title: document.title,
              });
              // Beacon('suggest');
            }
          }
        } catch (e) {
          console.warn(e);
        }
      });

      this.initToggleVisible();
    }
  }

  do(action: string, params: Record<string, string> | string = {}): void {
    try {
      Beacon(action, params);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        throw e;
      }
    }
  }

  private initToggleVisible(): void {
    this.eventBus.on('beaconService:hide').subscribe(() => {
      const element = document.getElementById('beacon-container');

      if (element) {
        element.style.transition = 'opacity .3s ease';
        element.style.opacity = '0';
      }
    });

    this.eventBus.on('beaconService:show').subscribe(() => {
      const element = document.getElementById('beacon-container');

      if (element) {
        element.style.transition = 'opacity .3s ease';
        element.style.opacity = '1';
      }
    });
  }

  destroy(): void {
    try {
      Beacon('destroy');
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        throw e;
      }
    }
  }
}
