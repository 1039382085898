<div class="unsupported-browser outer">
  <div class="inner">
    <div class="icon-logo">
      <mds-icon [icon]="icon" />
    </div>
    <h1>{{ 'UNSUPPORTED_BROWSER.BROWSER_SUPPORT_MESSAGE_PART1' | translate }}</h1>
    <p>{{ 'UNSUPPORTED_BROWSER.BROWSER_SUPPORT_MESSAGE_PART2' | translate }}:</p>
    <div class="browsers-links-container">
      <a class="browser-link" href="https://www.google.com/chrome/browser/desktop/" target="_blank">
        <mds-icon icon="pxChrome" />
        {{ 'UNSUPPORTED_BROWSER.GOOGLE_CHROME_LABEL' | translate }}
      </a>
      <a class="browser-link" href="https://www.microsoft.com/en-us/edge/" target="_blank">
        <mds-icon icon="pxEdge" />
        {{ 'UNSUPPORTED_BROWSER.MICROSOFT_EDGE_LABEL' | translate }}
      </a>
      <a class="browser-link" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
        <mds-icon icon="pxFirefox" />
        {{ 'UNSUPPORTED_BROWSER.MOZILLA_LABEL' | translate }}
      </a>
      <a class="browser-link" href="https://support.apple.com/downloads/safari/" target="_blank">
        <mds-icon icon="pxSafari" />
        {{ 'UNSUPPORTED_BROWSER.APPLE_SAFARI_LABEL' | translate }}
      </a>
    </div>
  </div>
</div>
