import {NgModule} from '@angular/core';
import {ProductsDataService} from './infrastructure/products.data.service';
import {BillingCheckoutSessionService} from './infrastructure/billing-checkout-session.service';
import {SessionCreationErrorTransformerService} from './infrastructure/session-creation-error-transformer.service';
import {CheckoutFacade} from './application/checkout-facade.service';
import {BillingProductsFacadeService} from './application/billing-products-facade.service';
import {BillingProductAdapterService} from './infrastructure/billing-product-adapter.service';
import {CustomerSubscriptionsFacadeService} from './application/customer-subscriptions-facade.service';
import {CustomerSubscriptionsDataService} from './infrastructure/customer-subscriptions.data.service';
import {JwtWrapperService} from './infrastructure/jwt-wrapper.service';
import {SubscriptionActivationFacade} from './application/subscription-activation-waiting-facade.service';

@NgModule({
  providers: [
    ProductsDataService,
    BillingCheckoutSessionService,
    SessionCreationErrorTransformerService,
    CheckoutFacade,
    BillingProductsFacadeService,
    BillingProductAdapterService,
    CustomerSubscriptionsFacadeService,
    CustomerSubscriptionsDataService,
    JwtWrapperService,
    SubscriptionActivationFacade,
  ],
})
export class PricingDomainModule {}
