import {IBillingProductClient} from './billing-product-client';

export type SubscriptionActivationState<T = boolean | null> = {
  billingProduct?: IBillingProductClient;
  isActivating: T;
  activationResult?: {
    isActivated: boolean;
    error?: Error;
  };
};
