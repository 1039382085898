import {Observable} from 'rxjs';
import {IBillingProductClient} from '../entities/billing-product-client';
import {ICheckoutParams} from './checkout-params';

export interface ICheckoutFacade {
  jwt?: string;

  checkoutSuccess$: Observable<IBillingProductClient>;
  checkoutError$: Observable<Error>;
  checkoutOpen$: Observable<IBillingProductClient>;
  checkoutClosed$: Observable<void>;
  initBillingProvider(site: string, apiKey: string, billingProviderSDKUrl: string): Promise<void>;
  openCheckout(params: ICheckoutParams): void;
}
