import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'roundSeconds',
})
export class RoundSecondsPipe implements PipeTransform {
  transform(value: number | string): number | string {
    return typeof value === 'number' ? Math.round(value / 1000) * 1000 : value;
  }
}
