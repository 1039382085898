import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MdsIconComponent} from '@pui/components/icon-core';
import {FeaturePageMobileNotSupportedComponent} from './feature-page-mobile-not-supported/feature-page-mobile-not-supported.component';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterLink, MdsIconComponent],
  declarations: [FeaturePageMobileNotSupportedComponent],
  exports: [FeaturePageMobileNotSupportedComponent],
})
export class FeaturePageMobileNotSupportedModule {}
