import {SlideshowOptionsActionsUnion, SlideshowOptionsActionTypes} from './slideshow-options.actions';
import {Slideshow} from 'apps/pss/src/app/models/slideshow.model';
import {MdsDialogRef} from '@pui/components/dialog';

export interface State {
  slideshow: Slideshow | null;
  dialogRef: MdsDialogRef<unknown, unknown> | null;
  saving: boolean;
}

export const initialState: State = {
  slideshow: {
    common_data: {
      call_to_action: {
        button_name: null,
        enabled: false,
        external_link: null,
      },
      password: null,
      is_download_button: false,
      download_pin: null,
    },
    name: '',
    slug: null,
  },
  dialogRef: null,
  saving: false,
};

// Reducer
export function reducer(state: State = initialState, action: SlideshowOptionsActionsUnion): State {
  switch (action.type) {
    case SlideshowOptionsActionTypes.OPENED:
      return {
        ...state,
        dialogRef: action.dialogRef,
        slideshow: {
          ...state.slideshow,
          ...action.slideshow,
          common_data: {...state.slideshow.common_data, ...action.slideshow.common_data},
        },
      };

    case SlideshowOptionsActionTypes.UPDATE:
      return {
        ...state,
        slideshow: {
          ...state.slideshow,
          ...action.payload,
        },
      };

    case SlideshowOptionsActionTypes.CREATE:
      return {
        ...state,
        saving: true,
      };

    case SlideshowOptionsActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        saving: false,
      };

    case SlideshowOptionsActionTypes.CREATE_FAILURE:
      return {
        ...state,
        saving: false,
      };

    case SlideshowOptionsActionTypes.RESET_STATE:
      return {...initialState};

    default:
      return state;
  }
}

export const getSlideshowData = (state: State) => state.slideshow;
export const getSlideshowSaving = (state: State) => state.saving;
export const getDialogRef = (state: State) => state.dialogRef;
