export enum DeleteSegmentSource {
  DELETE_SONG = 'Delete Song',
  DELETE_SONG_AND_IMAGES = 'Delete Song and Images',
}

export enum DownloadVideoSource {
  STYLE_AND_PREVIEW_NAV_BAR = 'Style and Preview - Navigation Bar',
  STYLE_AND_PREVIEW_PUBLISH_DIALOG = 'Style and Preview - Publish Confirmation Dialog',
  STYLE_AND_PREVIEW_SHARE_DIALOG = 'Style and Preview - Share Dialog',
  MANAGER = 'Slideshow Manager',
  MANAGER_SHARE_DIALOG = 'Slideshow Manager - Share Dialog',
}

export enum DownloadVideoType {
  HD = 'HD',
  ULTRA_HD = '4K',
}

export enum PlayModalActivation {
  DOUBLE_CLICK = 'thumbnail double click',
  THUMBNAIL_ICON = 'thumbnail icon',
}

export enum NavigateToManagerSource {
  MANAGER_ICON = 'Slideshow Manager Icon',
  LOGO_ICON = 'SmartSlides Logo Icon',
}

export enum EnableBMManuallyAction {
  NONE = 'None',
  DELETE_IMAGES = 'Delete images',
  MOVE_IMAGES = 'Move images',
  ADD_IMAGES = 'Add images',
  TRIM_MUSIC = 'Trim music',
}

export enum SlideshowType {
  SAMPLE = 'Sample',
}
