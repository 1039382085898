import {APP_INITIALIZER, inject, StaticProvider} from '@angular/core';
import {FirstPromoterService} from './services/first-promoter.service';
import {PlatformEnvironment} from '@px/shared/env';

export const FIRST_PROMOTER_INITIALIZER: StaticProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: () => {
    const platformEnv = inject(PlatformEnvironment);
    const firstPromoterService = inject(FirstPromoterService);

    return () =>
      platformEnv.FIRST_PROMOTER_ENABLED ? firstPromoterService.init(platformEnv.FIRST_PROMOTER) : Promise.resolve();
  },
};
