import {Observable} from 'rxjs';
import {IConfig} from './config.interface';
import {PxBillingProviderCheckoutSession, PxBillingProviderPortalSession} from './chargebee.interface';

export interface IBillingProviderFacade {
  isCheckoutOpened$: Observable<void>;
  isCheckoutClosed$: Observable<void>;

  subscriptionChanged$: Observable<void>;
  subscriptionCancelled$: Observable<void>;

  isCustomerPortalOpened$: Observable<void>;
  isCustomerPortalClosed$: Observable<void>;

  isCheckoutSuccess$: Observable<void>;
  isCheckoutError$: Observable<Error>;

  isReady$: Observable<boolean>;

  setUp(sdkUrl: string): Promise<void>;
  initialize(config: IConfig): void;
  openCheckout(session: PxBillingProviderCheckoutSession): void;
  openCustomerPortal(session: PxBillingProviderPortalSession): void;
  close(): void;
}
