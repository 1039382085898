export enum AudioEnergy {
  HIGH = 'HIGH',
  LOW = 'LOW',
  LOW_MEDIUM = 'LOW_MEDIUM',
  MEDIUM = 'MEDIUM',
  MEDIUM_HIGH = 'MEDIUM_HIGH',
}

/** @deprecated ids should map via map use AudioEnergy instead */
export enum AudioEnergyId {
  LOW = 0,
  LOW_MEDIUM = 1,
  MEDIUM = 2,
  MEDIUM_HIGH = 3,
  HIGH = 4,
}
