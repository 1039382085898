import {createSelector} from '@ngrx/store';

import {AppSelectors} from '../../store/app/app.selectors';
import {AuthStatusState, LoginPageState, User} from '../../models/user.model';
import {countUrls} from '../functions/auth-utils';
import {AuthState} from './auth.state';

export namespace AuthSelectors {
  export const selectAuthStatusState = createSelector(
    AppSelectors.getAuthState,
    (state: AuthState) => state.statusState
  );

  export const getLoggedIn = createSelector(selectAuthStatusState, (state: AuthStatusState) => state.isAuthenticated);

  export const getUser = createSelector(selectAuthStatusState, (state: AuthStatusState) => state.user);

  export const getS3Signature = createSelector(selectAuthStatusState, (state: AuthStatusState) => state.s3Signature);

  export const getImagesUrls = createSelector(getUser, (user: User | null) => countUrls(user));

  export const selectLoginPageState = createSelector(
    AppSelectors.getAuthState,
    (state: AuthState) => state.loginPageState
  );
  export const getLoginPageError = createSelector(selectLoginPageState, (state: LoginPageState) => state.errorMessage);
  export const getLoginPagePending = createSelector(
    selectLoginPageState,
    (state: LoginPageState) => state.pendingLogin
  );
}
