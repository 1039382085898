<div class="period-selector" [ngClass]="{'period-selector--disabled': disabled}">
  <span class="period-selector__label" [class.period-selector__label--selected]="value === BillingPeriod.YEARLY">{{
    'Billed yearly' | translate
  }}</span>
  <px-pricing-toggle
    class="period-selector__toggle"
    [value]="value === BillingPeriod.YEARLY"
    (valueChange)="changePeriod($event)"
  />
  <span class="period-selector__label" [class.period-selector__label--selected]="value === BillingPeriod.MONTHLY">{{
    'Billed monthly' | translate
  }}</span>
</div>
