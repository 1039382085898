<div class="start-step" flex="flex" layout="column" layout-align="center center" *ngIf="currentQuestionIndex < 0">
  <div class="title">{{ 'SURVEY_DIALOG.TITLE' | translate }}</div>
  <div class="description" [innerHTML]="'SURVEY_DIALOG.DESCRIPTION' | translate"></div>
  <button
    class="px-btn-paddings"
    mat-flat-button
    color="primary"
    (click)="surveyStart()"
    tabindex="1"
    [attr.aria-label]="'GLOBAL.BEGIN_LABEL' | translate"
  >
    <span>{{ 'SURVEY_DIALOG.BEGIN' | translate }}</span>
  </button>
</div>
<div
  class="questions-step"
  flex="flex"
  layout="column"
  layout-align="center center"
  *ngIf="currentQuestionIndex >= 0 && currentQuestionIndex < questions.length"
>
  <div>
    <div class="survey-progress">
      <span>
        {{ 'SURVEY_DIALOG.SURVEY_QUESTION' | translate }} ({{ currentQuestionIndex + 1 }}/{{ questions.length }}):
      </span>
      <button
        class="step-indicator"
        *ngFor="let question of questions; let idx = index"
        [disabled]="idx > maxQuestionIndex || isSaving"
        [ngClass]="{selected: idx === currentQuestionIndex}"
        (click)="surveySelect(idx)"
        [attr.aria-label]="'GLOBAL.NEXT_LABEL' | translate"
      ></button>
    </div>
    <div *ngFor="let question of questions; let idx = index">
      <ng-container *ngIf="idx === currentQuestionIndex">
        <div class="question">{{ question.question }}</div>
        <mat-form-field>
          <mat-label>{{ 'SURVEY_DIALOG.CHOOSE_ONE' | translate }}</mat-label>
          <mat-select
            [compareWith]="compareOptions"
            placeholder="Choose one"
            (selectionChange)="optionChange($event, question)"
            [(value)]="question.selectedOptionId"
          >
            <mat-option *ngFor="let option of question.options" [value]="option.id">
              {{ option.option }}
            </mat-option>
          </mat-select></mat-form-field
        >
      </ng-container>
      <button
        class="next-button px-btn-paddings"
        mat-flat-button
        color="primary"
        (click)="next()"
        tabindex="2"
        *ngIf="currentQuestionIndex + 1 < questions.length"
        [attr.aria-label]="'GLOBAL.NEXT_LABEL' | translate"
        [disabled]="!questions[currentQuestionIndex].selectedOptionId"
      >
        <span>{{ 'SURVEY_DIALOG.NEXT' | translate }}</span></button
      ><button
        class="next-button px-btn-paddings"
        mat-flat-button
        color="primary"
        (click)="send()"
        tabindex="2"
        *ngIf="currentQuestionIndex + 1 === questions.length"
        [attr.aria-label]="'GLOBAL.NEXT_LABEL' | translate"
        [disabled]="!questions[currentQuestionIndex].selectedOptionId || isSaving"
      >
        <span>{{ 'SURVEY_DIALOG.SEND' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<div
  class="finish-step"
  flex="flex"
  layout="column"
  layout-align="center center"
  *ngIf="currentQuestionIndex === questions.length"
>
  <div class="title">{{ 'SURVEY_DIALOG.THANK_YOU' | translate }}</div>
  <img src="assets/images/thank-you-icon.svg" alt="Thank you" />
</div>
