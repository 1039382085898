import {PSSPlatformEnvironment} from '../../platform-environment';
import {WindowService} from '../services/window.service';

const winRef = new WindowService();

export function getDevicePixelRatio(): number {
  const platform = PSSPlatformEnvironment.getSelf();
  let ratio = 1;

  const screenObj: Screen = winRef.nativeWindow.screen;
  if ('systemXDPI' in screenObj && 'logicalXDPI' in screenObj && screenObj['systemXDPI'] > screenObj['logicalXDPI']) {
    ratio = (screenObj['systemXDPI'] as number) / (screenObj['logicalXDPI'] as number);
  } else if (winRef.nativeWindow.devicePixelRatio !== undefined) {
    ratio = winRef.nativeWindow.devicePixelRatio;
  } else {
    ratio = 1;
  }

  if (ratio > 1) {
    const zoom = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
    if (zoom !== 1) ratio = ratio / zoom;
  }

  if (ratio < 1) ratio = 1;
  ratio = Math.round(ratio * 100) / 100;
  if (ratio > platform.COMMON_SETTINGS.MAX_DPR) {
    ratio = platform.COMMON_SETTINGS.MAX_DPR;
  }

  return isNaN(ratio) ? 1 : ratio;
}
