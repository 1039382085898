import {inject, Injectable} from '@angular/core';
import {IMdsDialogConfig, MDS_DIALOG_SERVICE, MdsDialogRef} from '@pui/components/dialog';
import {ISubscriptionManagerDialogData} from '../entities/subscription-manager-dialog-data';
import {SubscriptionManagerDialogComponent} from '../components/subscription-manager-dialog.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {IBillingProductClient, SubscriptionActivationState} from '@ps/pricing-domain';

@UntilDestroy()
@Injectable()
export class SubscriptionManagerDialogService {
  private readonly uiDialogService = inject(MDS_DIALOG_SERVICE);
  private subscriptionManagerDialogRef: MdsDialogRef<SubscriptionManagerDialogComponent, unknown> | null = null;

  /** @deprecated use eventBus instead */
  checkoutOpen$ = new Subject<IBillingProductClient>();
  /** @deprecated use eventBus instead */
  checkoutSuccess$ = new Subject<IBillingProductClient>();
  /** @deprecated use eventBus instead */
  checkoutError$ = new Subject<Error>();
  /** @deprecated use eventBus instead */
  upgradeSuccess$ = new Subject<IBillingProductClient>();
  /** @deprecated use eventBus instead */
  subscriptionActivationState$ = new Subject<SubscriptionActivationState>();
  /** @deprecated use eventBus instead */
  subscriptionActivatedActionClick$ = new Subject<void>();

  open(dialogData?: ISubscriptionManagerDialogData): void {
    const config: IMdsDialogConfig<ISubscriptionManagerDialogData> = {
      noPaddingOnSmallViewport: true,
    };

    config.data = dialogData;

    this.subscriptionManagerDialogRef = this.uiDialogService.open(SubscriptionManagerDialogComponent, config);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.checkoutOpen$
      .pipe(untilDestroyed(this))
      .subscribe(this.checkoutOpen$);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.checkoutSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(this.checkoutSuccess$);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.upgradeSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(this.upgradeSuccess$);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.subscriptionActivationState$
      .pipe(untilDestroyed(this))
      .subscribe(this.subscriptionActivationState$);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.checkoutError$
      .pipe(untilDestroyed(this))
      .subscribe(this.checkoutError$);

    this.subscriptionManagerDialogRef.containerRef?.instance?.contentComponent?.subscriptionActivatedActionClick$
      .pipe(untilDestroyed(this))
      .subscribe(this.subscriptionActivatedActionClick$);

    this.subscriptionManagerDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.subscriptionManagerDialogRef = null;
      });
  }

  close(): void {
    this.subscriptionManagerDialogRef?.close();
  }
}
