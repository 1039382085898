import {createAction, props} from '@ngrx/store';
import {User} from '../../models/user.model';

export const LogInCheck = createAction('[Auth] Login Check');
export const LogInSuccess = createAction('[Auth] Login Success', props<{user: User; redirect?: boolean}>());
export const LogInFailure = createAction('[Auth] Login Failure', props<{error: string}>());
export const LogInRedirect = createAction('[Auth] Login Redirect');
export const MainPageRedirect = createAction('[Auth] Main Page Redirect');
export const UpdateUser = createAction('[Auth] Update User', (user: Partial<User>) => ({
  user,
}));
export const UpdateUserSuccess = createAction('[Auth] Update User Success', (user: Partial<User>) => ({
  user,
}));
export const SaveLastChosenTransition = createAction('[Auth] Save Last Chosen Transition', (user: Partial<User>) => ({
  user,
}));
export const LogOut = createAction('[Auth] Logout');
export const ResetError = createAction('[Auth] Reset Error');
