import {TemplateRef} from '@angular/core';
import {PuiOverlayDirective} from './overlay.directive';

export const PUI_OVERLAY_DEFAULT_DELAY = 150;

export const PUI_OVERLAY_POSITION_OFFSET = 4;

export const PUI_OVERLAY_POSITION_OFFSET_CONTEXT = 2;

export type PuiOverlayPosition =
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'top'
  | 'leftTop'
  | 'leftBottom'
  | 'left'
  | 'rightTop'
  | 'rightBottom'
  | 'right';

export type PuiOverlayTrigger = 'click' | 'hover' | 'contextmenu' | 'off';

export type PuiOverlayData = TemplateRef<{
  overlayRef: PuiOverlayDirective;
  overlayData: unknown;
}>;
