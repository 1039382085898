<div class="slideshows-count">
  <span>
    {{
      'MANAGER_PAGE.MANAGER_SLIDESHOWS_USAGE_MESSAGE'
        | translate: {slideshows_count: currentCount, slideshows_limit: availableCount}
    }}
  </span>
  <ng-container *ngIf="hasUpgradeButton">
    <span class="upgrade-btn upgrade-btn--link-like" (click)="upgrade()">
      {{ 'MANAGER_PAGE.MANAGER_UPGRADE_NOW' | translate }}
    </span>
  </ng-container>
</div>
