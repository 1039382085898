<div
  class="tooltip"
  [ngClass]="{
    'tooltip--w-small': width === 'small',
    'tooltip--w-medium': width === 'medium',
    'tooltip--w-large': width === 'large',
    'tooltip--w-x-large': width === 'xLarge',
    'tooltip--w-auto': width === 'auto',
    'tooltip--w-nowrap': width === 'nowrap'
  }"
  data-qa="tooltip-container"
  [@mdsFadeInOut]="animationState"
  (@mdsFadeInOut.start)="onAnimationStart($event)"
  (@mdsFadeInOut.done)="onAnimationDone($event)"
>
  <div class="tooltip__inner" data-qa="tooltip-container-content">
    <ng-container *ngIf="!wrapBalancer; else withWrapBalancerTpl">
      <ng-container *ngIf="asString as string">
        <span [innerText]="string"></span>
      </ng-container>
      <ng-container *ngIf="asTemplate as template">
        <ng-template [ngTemplateOutlet]="template"></ng-template>
      </ng-container>
    </ng-container>
    <ng-template #withWrapBalancerTpl>
      <mds-wrap-balancer>
        <ng-container *ngIf="asString as string">
          <span [innerText]="string"></span>
        </ng-container>
        <ng-container *ngIf="asTemplate as template">
          <ng-template [ngTemplateOutlet]="template"></ng-template>
        </ng-container>
      </mds-wrap-balancer>
    </ng-template>
  </div>
</div>
