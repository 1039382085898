import {ChangeDetectorRef, inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

export const MDS_APP_LOADER_EL = 'mds-app-loader--loading';

@Injectable({
  providedIn: 'root',
})
export class MdsAppLoaderService {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly document = inject(DOCUMENT);

  completeLoading(): void {
    const appLoaderEl = this.document.querySelector(`.${MDS_APP_LOADER_EL}`);

    appLoaderEl?.classList.remove(MDS_APP_LOADER_EL);
    this.cdr.markForCheck();
  }
}
