import {BreakpointObserver} from '@angular/cdk/layout';
import {Injectable, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Observable, map, shareReplay} from 'rxjs';
import {PUI_LAYOUT_BREAKPOINTS} from './layout.token';

@Injectable()
export class LayoutService {
  private readonly breakpoints = inject(PUI_LAYOUT_BREAKPOINTS);
  private readonly breakpointObserver = inject(BreakpointObserver);

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.Mobile).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isMobileLandscape$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.MobileLandscape).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isMobilePortrait$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.MobilePortrait).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isTablet$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.Tablet).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isTabletLandscape$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.TabletLandscape).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isTabletPortrait$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.TabletPortrait).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isDesktop$: Observable<boolean> = this.breakpointObserver.observe('(min-width: 1024px)').pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isDesktopLandscape$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.DesktopLandscape).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isDesktopPortrait$: Observable<boolean> = this.breakpointObserver.observe(this.breakpoints.DesktopPortrait).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isPortrait$: Observable<boolean> = this.breakpointObserver.observe('(orientation: portrait)').pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isLandscape$: Observable<boolean> = this.breakpointObserver.observe('(orientation: landscape)').pipe(
    map(result => result.matches),
    shareReplay(1)
  );

  isMobile = toSignal(this.isMobile$, {initialValue: false});
  isMobileLandscape = toSignal(this.isMobileLandscape$, {initialValue: false});
  isMobilePortrait = toSignal(this.isMobilePortrait$, {initialValue: false});
  isTablet = toSignal(this.isTablet$, {initialValue: false});
  isTabletLandscape = toSignal(this.isTabletLandscape$, {initialValue: false});
  isTabletPortrait = toSignal(this.isTabletPortrait$, {initialValue: false});
  isDesktop = toSignal(this.isDesktop$, {initialValue: false});
  isDesktopLandscape = toSignal(this.isDesktopLandscape$, {initialValue: false});
  isDesktopPortrait = toSignal(this.isDesktopPortrait$, {initialValue: false});
  isPortrait = toSignal(this.isPortrait$, {initialValue: false});
  isLandscape = toSignal(this.isLandscape$, {initialValue: false});
}
