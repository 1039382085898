import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {IFirstPromoterConfig} from '../types/first-promoter.models';
import {WINDOW_TOKEN} from '@px/cdk/window';

interface IWindow extends Window {
  $FPROM?: {
    trackSignup: (data: {email: string; uid: string | number}, callback?: () => void) => void;
    init: (token: string, domain: string) => void;
  };
}

@Injectable({providedIn: 'root'})
export class FirstPromoterService {
  private readonly window: IWindow = inject(WINDOW_TOKEN);
  private readonly document: Document = inject(DOCUMENT);
  private readonly FIRST_PROMOTER_SRC = 'https://cdn.firstpromoter.com/fprom.js';

  init(config: IFirstPromoterConfig): Promise<void> {
    return new Promise(resolve => {
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = this.FIRST_PROMOTER_SRC;

      script.onload = (): void => {
        try {
          this.window.$FPROM?.init(config.TOKEN, config.DOMAIN);
        } catch (error) {
          console.error(error);
        }
      };

      this.document.head.appendChild(script);

      resolve();
    });
  }

  trackRegistration(userEmail: string, userId: string | number): void {
    this.window.$FPROM?.trackSignup({
      email: userEmail,
      uid: userId,
    });
  }
}
