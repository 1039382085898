import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';

@Pipe({
  name: 'billingEndDate',
})
export class BillingEndDatePipe implements PipeTransform {
  transform(value?: Date | number): string {
    return value ? formatDate(+value * 1000, 'MMMM d, y', 'en-US') : '-';
  }
}
