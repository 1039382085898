import {Action, createAction} from '@ngrx/store';

import {
  Locals,
  Photo,
  PhotoEntityUpdate,
  PhotoFileItem,
  Segment,
  SegmentEntityChanges,
  Slideshow,
} from '../../models/slideshow.model';
import {User} from '../../models/user.model';
import {SlideShowState} from './slideshow.state';
import {HttpErrorResponse} from '@angular/common/http';
import {EditorView} from '../../models/enums/editor-view';
import {ExtractField} from '../../core/interfaces/extract-field';
import {SlideshowChangesApi} from '../../core/interfaces/slideshow-changes-api';
import {Preferences} from '../../models/preferences.model';
import {PartialSlideshowSaveResponse} from '../../core/interfaces/slideshow-save-response';
import {ClickPhotoEvent} from '../../main/editor/types/click-photo-event.model';
import {DownloadVideoType} from '@px/pss/feature-tracking';
import {DeepPartial} from 'ts-essentials';
import {ImageSortType} from '../../models/enums/photos-sort.enum';
import {IPSSTransition} from '../../models/pss-transition';
import {IPSSTemplate} from '../../models/pss-template';
import {IAudio} from '@px/shared/api';

export enum SlideshowActionTypes {
  LOAD = '[Slideshow] Load',
  LOAD_SLIDESHOW_DATA = '[Slideshow] Load Slideshow Date Received From The Server',
  LOAD_FAILURE = '[Slideshow] Load Failure',
  REDISTRIBUTE_IMAGES = '[Slideshow] Redistribute Images',
  AUTO_SAVE = '[Slideshow] Auto Save',
  AUTO_SAVE_STOP = '[Slideshow] Auto Save Stop',
  AUTO_SAVE_START = '[Slideshow] Auto Save Start',
  AUTO_SAVE_FINISH = '[Slideshow] Auto Save Finish',
  AUTO_SAVE_SUCCESS = '[Slideshow] Auto Save Success',
  UPDATE_SAVED_STATE = '[Slideshow] Update Saved State',
  REVERT_CHANGES = '[Slideshow] Revert Changes',
  REVERT_FROM_SAVED_STATE = '[Slideshow] Revert From Saved State',
  SAVE = '[Slideshow] Save',
  SAVE_SUCCESS = '[Slideshow] Save Success',
  SAVE_FAILURE = '[Slideshow] Save Failure',
  CHANGE_VIEW_FINISHED = '[Slideshow] Change View Finished',
  CREATE_SEGMENT = '[Slideshow] Create Segment',
  JOIN_AUDIO = '[Slideshow] Join Audio',
  CHANGE_AUDIO_IN_SEGMENT = '[Slideshow] Change Audio in Segment',
  UPDATE_AUDIOS_IN_SEGMENTS = '[Slideshow] Update Audios in Segment',

  DELETE_SEGMENT_START = '[Slideshow] Start process of deleting segment',
  DELETE_SEGMENT = '[Slideshow] Delete Segment',

  SELECT_SEGMENT = '[Slideshow] Select Segment',

  UPDATE_SEGMENTS = '[Slideshow] Update Segments',
  UPDATE_SEGMENT = '[Slideshow] Update Segment',
  UPDATE_LOCALS = '[Slideshow] Update Locals',
  UPDATE_SLIDESHOW = '[Slideshow] Update',
  UPDATE_PHOTOS = '[Slideshow] Update photos',

  SELECT_IMAGES_SORT_TYPE = '[Slideshow] Select Images Sort Type',
  COUNT_DURATION_PER_SLIDE = '[Slideshow] Count Duration Per Slide',
  MOUSE_OVER_PHOTO = '[Slideshow] Mouse Over/Left Photo',
  DELETE_PHOTOS = '[Slideshow] Delete Photos',
  DELETE_IMAGES_CONFIRMATION_OPEN = '[Slideshow] Delete Images Confirmation Open',
  REORDER_SEGMENTS = '[Slideshow] Reorder Segments',
  REORDER_IMAGES = '[Slideshow] Reorder Images',
  MOVE_IMAGES_TO_ANOTHER_SEGMENT = '[Slideshow] Move Images To Another Segment',
  MOVE_IMAGES_TO_ANOTHER_SEGMENT_CONFIRMATION = '[Slideshow] Reorder Images Confirmation',
  VIDEO_READY = '[Slideshow] Video Ready',
  DESTROY = '[Slideshow] Destroy',

  PUBLISH = '[Slideshow] Publish',
  PUBLISH_SUCCESS = '[Slideshow] Publish Success',
  PUBLISH_FAILURE = '[Slideshow] Publish Failure',

  ADD_PHOTOS_TO_SLIDESHOW = '[Slideshow] Add photos to slideshow',
  DUPLICATE_PHOTO = '[Slideshow] Duplicate photos',
  CHANGE_IS_DRAGGING = '[Slideshow] Change is dragging',
  MOVE_PHOTOS_TO_NEW_SEGMENT = '[Slideshow] Move Photos To New Segment',
  UPDATE_TRANSITION = '[Slideshow] Update Transition',
  UPDATE_SEGMENTS_TRANSITIONS = '[Slideshow] Update Segments Transitions',

  TURN_SEGMENT_BM_OFF = '[Slideshow] Turn segment BeatMatching Off',
  TURN_SEGMENT_BM_ON = '[Slideshow] Turn segment BeatMatching On',
  TURN_SEGMENT_BM_ON_RESULT = '[Slideshow] Turn segment BeatMatching on result',
  TRIM_AUDIO_FOR_BM = '[Slideshow] Trim Audio For BeatMatching',
  OPEN_BM_DIALOG = '[Slideshow] Open BM dialog',

  TRIMMING_AUDIO_START = '[Slideshow] Trimming Audio Start',
  TRIMMING_AUDIO = '[Slideshow] Trimming Audio',
  TRIMMING_AUDIO_STOP = '[Slideshow] Trimming Audio Stop',
  TRIMMING_AUDIO_CONFIRMATION = '[Slideshow] Trimming Audio Confirmation',
  TRIMMING_AUDIO_SUCCESS = '[Slideshow] Trimming Audio Success',
}

export class Load implements Action {
  readonly type = SlideshowActionTypes.LOAD;

  constructor(
    public slug: string,
    public initAutoSave: boolean = false
  ) {}
}

export class LoadSlideshowData implements Action {
  readonly type = SlideshowActionTypes.LOAD_SLIDESHOW_DATA;

  constructor(
    readonly slideshow: Slideshow,
    readonly userPreferences?: Partial<Preferences>,
    readonly initAutoSave?: boolean
  ) {}
}

export const loadSlideshowSuccess = createAction(
  '[Slideshow] Load Success',
  (slideshow: Omit<Slideshow, 'segments'>, segments: Segment[], photos: Photo[]) => ({slideshow, segments, photos})
);

export const uploadPhotoToSlideshowSuccess = createAction(
  '[Slideshow] Upload photo to slideshow success',
  (itemId: string, slideshowUniqueIdentifier: string, photo: Photo) => ({itemId, slideshowUniqueIdentifier, photo})
);

export const discardFailedImages = createAction('[Slideshow] Discard failed images');

export class LoadFailure implements Action {
  readonly type = SlideshowActionTypes.LOAD_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class RedistributeImages implements Action {
  readonly type = SlideshowActionTypes.REDISTRIBUTE_IMAGES;
}

export class RevertChanges implements Action {
  readonly type = SlideshowActionTypes.REVERT_CHANGES;
}

export class AutoSave implements Action {
  readonly type = SlideshowActionTypes.AUTO_SAVE;

  constructor(public saveImmediately = false) {}
}

export class AutoSaveStop implements Action {
  readonly type = SlideshowActionTypes.AUTO_SAVE_STOP;
}

export class AutoSaveStart implements Action {
  readonly type = SlideshowActionTypes.AUTO_SAVE_START;
}

export class AutoSaveFinish implements Action {
  readonly type = SlideshowActionTypes.AUTO_SAVE_FINISH;
}

export class AutoSaveSuccess implements Action {
  readonly type = SlideshowActionTypes.AUTO_SAVE_SUCCESS;

  constructor(
    readonly changes: SlideshowChangesApi,
    readonly response: PartialSlideshowSaveResponse,
    readonly actual?: SlideShowState
  ) {}
}

export class UpdateSavedState implements Action {
  readonly type = SlideshowActionTypes.UPDATE_SAVED_STATE;

  constructor(public payload: SlideShowState) {}
}

export class RevertFromSavedState implements Action {
  readonly type = SlideshowActionTypes.REVERT_FROM_SAVED_STATE;

  constructor(public payload: string[]) {}
}

export class Save implements Action {
  readonly type = SlideshowActionTypes.SAVE;

  constructor(public slideshow: DeepPartial<Slideshow> = {}) {}
}

export class SaveSuccess implements Action {
  readonly type = SlideshowActionTypes.SAVE_SUCCESS;

  constructor(
    readonly changes: SlideshowChangesApi | null,
    readonly response: PartialSlideshowSaveResponse | null,
    readonly actual?: SlideShowState
  ) {}
}

export class SaveFailure implements Action {
  readonly type = SlideshowActionTypes.SAVE_FAILURE;

  constructor(public payload) {}
}

export const changeView = createAction('[Slideshow] Change View', (payload: EditorView) => ({payload}));

export class ChangeViewFinished implements Action {
  readonly type = SlideshowActionTypes.CHANGE_VIEW_FINISHED;
}

export class CreateSegment implements Action {
  readonly type = SlideshowActionTypes.CREATE_SEGMENT;

  constructor(
    readonly audio: IAudio<number | string> | null,
    readonly user: User,
    readonly atTheEnd = true,
    readonly skipSave = false
  ) {}
}

export class ChangeAudioInSegment implements Action {
  readonly type = SlideshowActionTypes.CHANGE_AUDIO_IN_SEGMENT;

  constructor(
    readonly audio: IAudio<number | string>,
    readonly user: User,
    readonly view: EditorView
  ) {}
}

export class UpdateAudiosInSegments implements Action {
  readonly type = SlideshowActionTypes.UPDATE_AUDIOS_IN_SEGMENTS;

  constructor(readonly audios: IAudio<number | string>[]) {}
}

export class DeleteSegment implements Action {
  readonly type = SlideshowActionTypes.DELETE_SEGMENT;

  constructor(
    readonly id: ExtractField<Segment, 'id'>,
    readonly targetSegmentId: ExtractField<Segment, 'id'> | null = null,
    readonly skipSave = false
  ) {}
}

export class SelectSegment implements Action {
  readonly type = SlideshowActionTypes.SELECT_SEGMENT;

  constructor(public id: ExtractField<Segment, 'id'>) {}
}

export class UpdateSegments implements Action {
  readonly type = SlideshowActionTypes.UPDATE_SEGMENTS;

  constructor(
    public changes: SegmentEntityChanges[],
    public changeState = true
  ) {}
}

export class UpdateSegment implements Action {
  readonly type = SlideshowActionTypes.UPDATE_SEGMENT;

  constructor(
    public changes: SegmentEntityChanges,
    public changeState = true
  ) {}
}

export class UpdateLocals implements Action {
  readonly type = SlideshowActionTypes.UPDATE_LOCALS;

  constructor(public payload: Partial<Locals>) {}
}

export class CountDurationPerSlide implements Action {
  readonly type = SlideshowActionTypes.COUNT_DURATION_PER_SLIDE;

  constructor(public payload?: Segment[]) {}
}

export class SelectImagesSortType implements Action {
  readonly type = SlideshowActionTypes.SELECT_IMAGES_SORT_TYPE;

  constructor(readonly sortType?: ImageSortType) {}
}

export class MouseOverPhoto implements Action {
  readonly type = SlideshowActionTypes.MOUSE_OVER_PHOTO;

  constructor(
    public photo: Photo,
    public $event: MouseEvent
  ) {}
}

export const selectPhoto = createAction('[Slideshow] Select Photo', (payload?: ClickPhotoEvent) => ({payload}));

export class DeletePhotos implements Action {
  readonly type = SlideshowActionTypes.DELETE_PHOTOS;

  constructor(
    public photosIds: ExtractField<Photo, 'id'>[],
    public photoNames?: ExtractField<Photo, 'name'>[]
  ) {}
}

export class DeleteImagesConfirmationOpen implements Action {
  readonly type = SlideshowActionTypes.DELETE_IMAGES_CONFIRMATION_OPEN;
}

export class ReorderSegments implements Action {
  readonly type = SlideshowActionTypes.REORDER_SEGMENTS;

  constructor(public ids) {}
}

export class ReorderImages implements Action {
  readonly type = SlideshowActionTypes.REORDER_IMAGES;

  constructor(
    readonly oldIndex: number,
    readonly targetIndex: number
  ) {}
}

export class MoveImagesToAnotherSegment implements Action {
  readonly type = SlideshowActionTypes.MOVE_IMAGES_TO_ANOTHER_SEGMENT;

  constructor(
    public targetId?: ExtractField<Segment, 'id'>,
    public sourceId?: ExtractField<Segment, 'id'>,
    public ids?: Array<number | string>
  ) {}
}

export class MoveImagesToAnotherSegmentConfirmation implements Action {
  readonly type = SlideshowActionTypes.MOVE_IMAGES_TO_ANOTHER_SEGMENT_CONFIRMATION;

  constructor(public targetSegmentId: ExtractField<Segment, 'id'>) {}
}

export class VideoReady implements Action {
  readonly type = SlideshowActionTypes.VIDEO_READY;

  constructor(
    public video_url: string,
    readonly videoType: DownloadVideoType
  ) {}
}

export class Destroy implements Action {
  readonly type = SlideshowActionTypes.DESTROY;
}

export class UpdateSlideshow implements Action {
  readonly type = SlideshowActionTypes.UPDATE_SLIDESHOW;

  constructor(public slideshow: DeepPartial<Slideshow>) {}
}

export class Publish implements Action {
  readonly type = SlideshowActionTypes.PUBLISH;
}

export class PublishSuccess implements Action {
  readonly type = SlideshowActionTypes.PUBLISH_SUCCESS;

  constructor(
    readonly slideshow: SlideShowState,
    readonly isPublished: boolean
  ) {}
}

export class PublishFailure implements Action {
  readonly type = SlideshowActionTypes.PUBLISH_FAILURE;

  constructor(public payload) {}
}

export class AddPhotosToSlideshow implements Action {
  readonly type = SlideshowActionTypes.ADD_PHOTOS_TO_SLIDESHOW;

  constructor(
    public payload: Record<ExtractField<Segment, 'id'>, Array<PhotoFileItem | Photo>>,
    public position?: number
  ) {}
}

export class JoinAudio implements Action {
  readonly type = SlideshowActionTypes.JOIN_AUDIO;

  constructor(public uniqueIdentifier: string) {}
}

export class UpdateTransition implements Action {
  readonly type = SlideshowActionTypes.UPDATE_TRANSITION;

  constructor(
    readonly transition: IPSSTransition,
    readonly updateAllSegments?: boolean
  ) {}
}

export class UpdateSegmentsTransitions implements Action {
  readonly type = SlideshowActionTypes.UPDATE_SEGMENTS_TRANSITIONS;

  constructor(
    readonly segmentsTransitions: Record<number, IPSSTransition>,
    readonly updateUserTransition = true
  ) {}
}

export class MovePhotosToNewSegment implements Action {
  readonly type = SlideshowActionTypes.MOVE_PHOTOS_TO_NEW_SEGMENT;

  constructor(
    public ids: ExtractField<Photo, 'id'>[],
    readonly sourceSegmentId: ExtractField<Segment, 'id'>
  ) {}
}

export class UpdatePhotos implements Action {
  readonly type = SlideshowActionTypes.UPDATE_PHOTOS;

  constructor(public payload: PhotoEntityUpdate[]) {}
}

export class DuplicatePhoto implements Action {
  readonly type = SlideshowActionTypes.DUPLICATE_PHOTO;

  constructor(public photo: Photo) {}
}

export class TurnSegmentBMOff implements Action {
  readonly type = SlideshowActionTypes.TURN_SEGMENT_BM_OFF;

  constructor(
    public changes: SegmentEntityChanges,
    public changeState = true
  ) {}
}

export class TurnSegmentBMOn implements Action {
  readonly type = SlideshowActionTypes.TURN_SEGMENT_BM_ON;

  constructor(
    readonly id?: number | string,
    readonly tppAction?: string
  ) {}
}

export class TurnSegmentBMOnResult implements Action {
  readonly type = SlideshowActionTypes.TURN_SEGMENT_BM_ON_RESULT;

  constructor(public success: boolean) {}
}

export class TrimAudioForBM implements Action {
  readonly type = SlideshowActionTypes.TRIM_AUDIO_FOR_BM;

  constructor(
    public segment: Segment,
    public template: IPSSTemplate
  ) {}
}

export class OpenBMDialog implements Action {
  readonly type = SlideshowActionTypes.OPEN_BM_DIALOG;
}

export class ChangeIsDragging implements Action {
  readonly type = SlideshowActionTypes.CHANGE_IS_DRAGGING;

  constructor(public isDragging: boolean) {}
}

export class TrimmingAudioStart implements Action {
  readonly type = SlideshowActionTypes.TRIMMING_AUDIO_START;
}

export class TrimmingAudio implements Action {
  readonly type = SlideshowActionTypes.TRIMMING_AUDIO;

  constructor(
    public changes: SegmentEntityChanges,
    public changeState = true
  ) {}
}

export class TrimmingAudioStop implements Action {
  readonly type = SlideshowActionTypes.TRIMMING_AUDIO_STOP;
}

export class TrimmingAudioConfirmation implements Action {
  readonly type = SlideshowActionTypes.TRIMMING_AUDIO_CONFIRMATION;

  constructor(public payload: Segment) {}
}

export class TrimmingAudioSuccess implements Action {
  readonly type = SlideshowActionTypes.TRIMMING_AUDIO_SUCCESS;
}

export class DeleteSegmentStart implements Action {
  readonly type = SlideshowActionTypes.DELETE_SEGMENT_START;

  constructor(
    public source: Segment,
    public target: Segment
  ) {}
}

export type SlideshowActionsUnion =
  | Load
  | LoadSlideshowData
  | ReturnType<typeof loadSlideshowSuccess>
  | LoadFailure
  | RedistributeImages
  | RevertChanges
  | AutoSave
  | AutoSaveStop
  | AutoSaveStart
  | AutoSaveFinish
  | AutoSaveSuccess
  | UpdateSavedState
  | RevertFromSavedState
  | Save
  | SaveSuccess
  | SaveFailure
  | ReturnType<typeof changeView>
  | ChangeViewFinished
  | CreateSegment
  | ChangeAudioInSegment
  | UpdateAudiosInSegments
  | DeleteSegment
  | SelectSegment
  | UpdateSegments
  | UpdateSegment
  | UpdateLocals
  | CountDurationPerSlide
  | SelectImagesSortType
  | MouseOverPhoto
  | ReturnType<typeof selectPhoto>
  | DeletePhotos
  | DeleteImagesConfirmationOpen
  | ReorderSegments
  | MoveImagesToAnotherSegment
  | MoveImagesToAnotherSegmentConfirmation
  | ReorderImages
  | VideoReady
  | Destroy
  | UpdateSlideshow
  | Publish
  | PublishFailure
  | PublishSuccess
  | AddPhotosToSlideshow
  | JoinAudio
  | UpdateTransition
  | UpdateSegmentsTransitions
  | MovePhotosToNewSegment
  | UpdatePhotos
  | DuplicatePhoto
  | ChangeIsDragging
  | TurnSegmentBMOff
  | TurnSegmentBMOn
  | TurnSegmentBMOnResult
  | TrimAudioForBM
  | OpenBMDialog
  | TrimmingAudioStart
  | TrimmingAudio
  | TrimmingAudioStop
  | TrimmingAudioConfirmation
  | TrimmingAudioSuccess;
