<mat-dialog-content flex="flex" layout="column" layout-align="center center">
  <px-survey-content
    [currentQuestionIndex]="currentQuestionIndex"
    [maxQuestionIndex]="maxQuestionIndex"
    [questions]="questions"
    [isSaving]="isSaving"
    (save$)="save$.emit()"
    (optionChange$)="optionChange$.emit($event)"
    (surveyStart$)="surveyStart$.emit()"
    (surveySelect$)="surveySelect$.emit($event)"
    (next$)="next$.emit()"
  />
</mat-dialog-content>
