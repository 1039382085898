import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService as AuthBase, IApiResponse as ApiResponse} from '@px/shared/api';
import {IPSSAuthService, ISaveLastTransitionPicInput} from '../models/auth-service.interface';

@Injectable()
export class AuthService extends AuthBase implements IPSSAuthService {
  private BASE_URL = this.platform.API_URL;

  saveLastTransition(data: ISaveLastTransitionPicInput): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.BASE_URL}save-last-chosen-transition/`, data);
  }
}
