import {NgModule} from '@angular/core';
import {BillingProviderFacade} from './application-services/billing-provider-facade.service';
import {CHARGEBEE} from './entities/tokens/chargebee.token';
import {IPxBillingProvider} from './entities/chargebee.interface';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Chargebee: IPxBillingProvider;
  }
}

@NgModule({
  providers: [BillingProviderFacade, {provide: CHARGEBEE, useFactory: (): IPxBillingProvider => window.Chargebee}],
})
export class PaymentPopoverDomainModule {}
