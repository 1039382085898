import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor() {
    (window as any).dataLayer = (window as any).dataLayer || [];
  }

  pushToDataLayer(data) {
    (window as any).dataLayer.push(data);
  }
}
