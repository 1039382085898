import {inject, Injectable} from '@angular/core';
import {MixpanelService, UserProps as UserPropsMP} from '@px/shared-data-access-mixpanel';
import {RudderstackService} from '@px/shared/data-access/rudderstack';
import {UserProps} from '../interfaces/user-props';
import {TrackingProperties} from '../models/tracking.types';
import {filter, firstValueFrom} from 'rxjs';

export enum TrackingServices {
  MIXPANEL = 'mixpanel',
  RUDDERSTACK = 'rudderstack',
}

@Injectable({providedIn: 'root'})
export class TrackingService {
  private readonly mixpanelService = inject(MixpanelService);
  private readonly rudderstackService = inject(RudderstackService);

  async init(): Promise<void> {
    await this.mixpanelService.init();
    await this.rudderstackService.init();
  }

  async ready(): Promise<void> {
    await firstValueFrom(this.mixpanelService.isEnabled$.pipe(filter(Boolean)));
    await firstValueFrom(this.rudderstackService.isEnabled$.pipe(filter(Boolean)));
  }

  track(
    id: string,
    action: TrackingProperties = {},
    services: TrackingServices[] = [TrackingServices.MIXPANEL, TrackingServices.RUDDERSTACK]
  ): void {
    if (services.includes(TrackingServices.MIXPANEL)) {
      this.mixpanelService.track(id, action);
    }
    if (services.includes(TrackingServices.RUDDERSTACK)) {
      this.rudderstackService.track(id, action);
    }
  }

  setSuperProperties(properties: TrackingProperties): void {
    this.mixpanelService.setSuperProperties(properties);
    this.rudderstackService.setSuperProperties(properties);
  }

  setUserProperties(properties: TrackingProperties & Partial<UserProps>): void {
    const {name, email, ...props} = properties;
    const userPropsMP: Partial<UserPropsMP> = {...props};

    if (name) {
      userPropsMP.$name = properties.name;
    }

    if (email) {
      userPropsMP.$email = properties.email;
    }

    this.mixpanelService.setUserProperties(userPropsMP);
    this.rudderstackService.setUserProperties(properties);
  }

  incrementUserProperties(property: string, by = 1): void {
    this.mixpanelService.incrementUserProperties(property, by);
    this.rudderstackService.incrementUserProperties(property, by);
  }

  decrementUserProperties(property: string): void {
    this.mixpanelService.incrementUserProperties(property, -1);
    this.rudderstackService.incrementUserProperties(property, -1);
  }

  identify(userId?: string | number, userPxId?: number): void {
    this.mixpanelService.identify(userId);
    this.rudderstackService.identify(userPxId || userId);
  }

  destroy(): void {
    this.mixpanelService.destroy();
    this.rudderstackService.destroy();
  }
}
