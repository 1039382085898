import {TemplateRef} from '@angular/core';

export type puiTooltipType = 'base';

export type puiTooltipPosition =
  | 'center'
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'top'
  | 'leftTop'
  | 'leftBottom'
  | 'left'
  | 'rightTop'
  | 'rightBottom'
  | 'right';

export type puiTooltipWidth = 'small' | 'medium' | 'large' | 'xLarge' | 'auto' | 'nowrap' | 'default';

export type puiTooltipData = TemplateRef<void> | string;

export const MDS_TOOLTIP_POSITION_OFFSET = 4;
