import {Injectable} from '@angular/core';
import {BillingCheckoutSessionService} from '../infrastructure/billing-checkout-session.service';
import {asyncScheduler, catchError, EMPTY, Subject, Subscription, tap} from 'rxjs';
import {BillingProviderFacade} from '@ps/payment-popover-domain';
import {ICheckoutSessionParams} from '../entities/checkout-session-params';
import {CheckoutSession} from '../entities/checkout-session';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IBillingProductClient} from '../entities/billing-product-client';
import {ICheckoutParams} from '../entities/checkout-params';
import {ICheckoutFacade} from '../entities/checkout-facade.interface';
import {JwtWrapperService} from '../infrastructure/jwt-wrapper.service';

@UntilDestroy()
@Injectable()
export class CheckoutFacade implements ICheckoutFacade {
  private initialised = false;
  private busy = false;
  private successSub?: Subscription;

  private readonly checkoutSuccessInternal$ = new Subject<IBillingProductClient>();
  private readonly checkoutErrorInternal$ = new Subject<Error>();
  private readonly checkoutOpenInternal$ = new Subject<IBillingProductClient>();
  private readonly checkoutClosedInternal$ = new Subject<void>();

  readonly checkoutSuccess$ = this.checkoutSuccessInternal$.asObservable();
  readonly checkoutError$ = this.checkoutErrorInternal$.asObservable();
  readonly checkoutOpen$ = this.checkoutOpenInternal$.asObservable();
  readonly checkoutClosed$ = this.checkoutClosedInternal$.asObservable();

  constructor(
    private readonly checkoutSessionService: BillingCheckoutSessionService,
    private readonly billingProviderFacade: BillingProviderFacade,
    private readonly jwtWrapperService: JwtWrapperService
  ) {
    this.billingProviderFacade.isCheckoutError$.pipe(untilDestroyed(this)).subscribe(this.checkoutErrorInternal$);
    this.billingProviderFacade.isCheckoutClosed$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.handleCheckoutClosed());
  }

  private handleCheckoutClosed(): void {
    this.busy = false;
    this.checkoutClosedInternal$.next();
  }

  private handleCheckoutSuccess(product: IBillingProductClient): void {
    this.billingProviderFacade.close();
    this.busy = false;
    asyncScheduler.schedule(() => this.checkoutSuccessInternal$.next(product));
  }

  private handleCheckoutError(error: Error): void {
    this.busy = false;
    this.checkoutErrorInternal$.next(error);
  }

  async initBillingProvider(site: string, apiKey: string, billingProviderSDKUrl: string): Promise<void> {
    if (this.initialised) {
      return;
    }

    await this.billingProviderFacade.setUp(billingProviderSDKUrl);

    this.billingProviderFacade.initialize({
      site,
      apiKey,
      iframeOnly: true,
    });

    this.initialised = true;
  }

  openCheckout(params: ICheckoutParams): void {
    if (this.busy) {
      return;
    }
    this.busy = true;

    this.successSub?.unsubscribe();

    this.successSub = this.billingProviderFacade.isCheckoutSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.handleCheckoutSuccess(params.billingProduct));

    const sessionParams: ICheckoutSessionParams = {
      priceId: params.billingProduct.id,
      quantity: params.quantity,
      cancelUrl: params.cancelUrl,
      redirectUrl: params.redirectUrl,
      couponCodes: params.couponCodes,
    };

    this.jwtWrapperService
      .wrap(token => this.checkoutSessionService.createCheckoutSession(sessionParams, token))
      .pipe(
        tap(session => this.billingProviderFacade.openCheckout(session as CheckoutSession)),
        tap(() => this.checkoutOpenInternal$.next(params.billingProduct)), //NOTE: not subscribed to billingProviderFacade.isCheckoutOpened$ because we need moment of opening start
        catchError(error => {
          this.handleCheckoutError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
