<ng-container *ngIf="isTrial; else titleRef">
  <div mat-dialog-title>{{ 'MANAGER_PAGE.TRIAL_LIMIT_REACHED_TITLE' | translate }}</div>
</ng-container>

<mat-dialog-content>
  <div class="help-text">
    <ng-container *ngIf="!isDuplicated && isTrial">
      <span [innerHTML]="'MANAGER_PAGE.TRIAL_LIMIT_REACHED_MESSAGE' | translate : {slideshows_limit: limit}"></span>
    </ng-container>

    <ng-container *ngIf="isDuplicated" [ngTemplateOutlet]="titleDuplicatedRef" />

    <ng-container *ngIf="!isDuplicated && !isTrial" [ngTemplateOutlet]="contentRef" />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    class="px-btn-paddings"
    mat-button
    color="primary"
    (click)="cancel()"
    tabindex="1"
    [attr.aria-label]="'GLOBAL.CANCEL_LABEL' | translate"
  >
    <span>{{ 'GLOBAL.CANCEL_LABEL' | translate }}</span>
  </button>

  <ng-container *ngIf="isTrial; else confirmRef">
    <px-button *ngIf="!upgradeUrl; else trialLink" type="primary" (click$)="handleUpdateClick()">
      {{ 'GLOBAL.UPGRADE_LABEL' | translate }}
    </px-button>
    <ng-template #trialLink>
      <a
        class="px-btn-paddings"
        mat-raised-button
        color="primary"
        tabindex="0"
        [innerHTML]="'GLOBAL.UPGRADE_LABEL' | translate"
        [href]="upgradeUrl"
        target="_blank"
        [attr.aria-label]="'GLOBAL.UPGRADE_LABEL' | translate"
      ></a>
    </ng-template>
  </ng-container>
</mat-dialog-actions>

<ng-template #titleRef>
  <div mat-dialog-title>{{ 'MANAGER_PAGE.PLAN_LIMIT_REACHED_TITLE' | translate }}</div>
</ng-template>

<ng-template #titleDuplicatedRef>
  <span [innerHTML]="'MANAGER_PAGE.DUPLICATE_LIMIT_REACHED_MESSAGE' | translate"></span>
</ng-template>

<ng-template #contentRef>
  <span [innerHTML]="'MANAGER_PAGE.PLAN_LIMIT_REACHED_MESSAGE' | translate"></span>
</ng-template>

<ng-template #confirmRef>
  <px-button *ngIf="!upgradeUrl; else switchLink" type="primary" (click$)="handleSwitchPlanClick()">
    {{ 'MANAGER_PAGE.SWITCH_PLAN_BUTTON' | translate }}
  </px-button>
  <ng-template #switchLink>
    <a
      class="px-btn-paddings"
      mat-raised-button
      color="primary"
      tabindex="0"
      [innerHTML]="'MANAGER_PAGE.SWITCH_PLAN_BUTTON' | translate"
      [href]="upgradeUrl"
      target="_blank"
      [attr.aria-label]="'MANAGER_PAGE.SWITCH_PLAN_BUTTON' | translate"
    ></a>
  </ng-template>
</ng-template>
