import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'px-feature-page-mobile-not-supported',
  templateUrl: './feature-page-mobile-not-supported.component.html',
  styleUrls: ['./feature-page-mobile-not-supported.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturePageMobileNotSupportedComponent {
  @Input() redirectUrl = '/';
  @Input() redirectUrlText?: string;
  @Input() companyUrl?: string;
  @Input() descriptions?: string[];
}
