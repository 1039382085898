import {superProperties} from './super-properties';
import {TrackableFeatures} from './trackable-features';

export const featuresProperties: Record<TrackableFeatures, string> = {};

export const userProperties: Record<string, string> = {
  PXID: 'PXID',
  PSS_USER_ID: 'PSS user ID',
  NUMBER_OF_CREATED_SLIDESHOWS: 'Total number of slideshows created',
  CURRENT_SUBSCRIPTION_PLAN_PSS: 'Current subscription plan PSS',
  NUMBER_OF_PUBLISHED_SLIDESHOWS: superProperties.NUMBER_OF_PUBLISHED_SLIDESHOWS,
  NUMBER_OF_SLIDESHOWS: superProperties.NUMBER_OF_SLIDESHOWS,
};
