import {ImageCroppingRatio} from '../enums/image-cropping-ratio';
import {ICroppingDataInput} from '../entities/update-segment-input.interface';
import {AspectRatio} from '@px/shared-utils-aspect-ratio';

export const RATIO_TO_INPUT_FIELD: Record<ImageCroppingRatio, keyof ICroppingDataInput> = {
  [ImageCroppingRatio.RATIO_16_9]: 'r16_9',
  [ImageCroppingRatio.RATIO_9_16]: 'r9_16',
};

export const ASPECT_RATIO_TO_INPUT_FIELD: Record<AspectRatio, keyof ICroppingDataInput> = {
  [AspectRatio.ASPECT_16_9]: 'r16_9',
  [AspectRatio.ASPECT_9_16]: 'r9_16',
};
