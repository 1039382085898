import {IBillingProductClient} from '../entities/billing-product-client';
import {BillingPeriod} from '../enums/billing-period';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export const BILLING_PRODUCT_CLIENTS: IBillingProductClient[] = [
  {
    id: 'billing-product-pss-id',
    name: 'billing product pss name',
    period: BillingPeriod.YEARLY,
    currencyCode: 'USD',
    productId: 'product-id',
    features: [],
    productFamily: ProductFamily.PSS,
    priceYearly: 12000,
    priceMonthly: 1000,
    promo: null,
    productName: 'Pixellu SmartSlides',
  },
  {
    id: 'billing-product-bundle-id',
    name: 'billing product bundle name',
    period: BillingPeriod.YEARLY,
    currencyCode: 'USD',
    productId: 'product-id',
    features: [],
    productFamily: ProductFamily.BUNDLE,
    promo: 'promo',
    priceYearly: 120000,
    priceMonthly: 10000,
    productName: 'Pixellu SmartSlides + Pixellu SmartAlbums',
  },
];
