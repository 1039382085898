import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MdsIcon} from '@pui/components/icon-core';

@Component({
  selector: 'px-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowserComponent {
  @Input() appLogo: MdsIcon = 'pxEmptyIcon';

  get icon(): MdsIcon {
    return this.appLogo;
  }
}
