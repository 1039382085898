import {inject, Injectable} from '@angular/core';
import {ProductsDataService} from '../infrastructure/products.data.service';
import {IBillingProductClient} from '../entities/billing-product-client';
import {BillingProductAdapterService} from '../infrastructure/billing-product-adapter.service';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {IBillingProductsFacade} from '../entities/billing-products-facade.interface';
import {IBillingProduct} from '../entities/billing-product';
import {map, Observable} from 'rxjs';

@Injectable()
export class BillingProductsFacadeService implements IBillingProductsFacade {
  private readonly billingProductsDataService = inject(ProductsDataService);
  private readonly billingProductAdapter = inject(BillingProductAdapterService);

  getProducts(
    productFamily: ProductFamily,
    highlightProduct: string | null = null,
    highlightProductTitle: string | null = null,
    originalProductFamily?: ProductFamily,
    ip?: string
  ): Observable<IBillingProductClient[]> {
    const originalProductFamilyValue = originalProductFamily || productFamily;

    return this.billingProductsDataService
      .getProducts(productFamily, ip)
      .pipe(
        map(
          (billingProducts: IBillingProduct[]) =>
            billingProducts
              .map((product: IBillingProduct) =>
                this.billingProductAdapter.transformToClient(
                  product,
                  originalProductFamilyValue,
                  highlightProduct,
                  highlightProductTitle
                )
              )
              .filter((p: IBillingProductClient | null): p is IBillingProductClient => !!p) as IBillingProductClient[]
        )
      );
  }
}
