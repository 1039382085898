import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {PuiTooltipDirective} from '@pui/components/tooltip';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'px-slideshow-name',
  standalone: true,
  imports: [PuiTooltipDirective],
  templateUrl: './slideshow-name.component.html',
  styleUrls: ['./slideshow-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideshowNameComponent implements OnDestroy {
  private readonly cdr = inject(ChangeDetectorRef);

  private elementInstance?: HTMLElement;
  private resizeObserver = new ResizeObserver(this.setTooltipStatus.bind(this));

  hasTooltip = true;

  @Input() name = '';

  @ViewChild('nameContainerRef', {static: true}) set element(value: ElementRef) {
    this.elementInstance = value?.nativeElement;

    if (this.elementInstance) {
      this.resizeObserver.observe(this.elementInstance);
    }
  }

  setTooltipStatus(): void {
    this.hasTooltip = (this.elementInstance?.offsetWidth ?? 0) < (this.elementInstance?.scrollWidth ?? 0);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }
}
