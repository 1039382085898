import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {PSSPlatformEnvironment} from 'apps/pss/src/app/platform-environment';

@Injectable()
export class IconsService {
  private icons$: Observable<Record<string, string>> = this.http
    .get<Record<string, string>>(`/assets/configs/icons.json?${this.platformEnvironment.getQueryString()}`)
    .pipe(shareReplay(1));

  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer,
    private readonly platformEnvironment: PSSPlatformEnvironment
  ) {}

  async registerIcons(): Promise<void> {
    const icons: Record<string, string> = await this.icons$.toPromise();

    for (const [name, path] of Object.entries(icons)) {
      this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }
}
