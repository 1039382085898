import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SaveSuccess, ShareDialogActionTypes} from 'apps/pss/src/app/store/share-dialog/share-dialog.actions';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addOrUpdateSlideshow} from 'apps/pss/src/app/store/manager/manager.actions';
import {ManagerSlideshow} from 'apps/pss/src/app/models/manager.model';
import {UpdateSlideshow} from 'apps/pss/src/app/store/slideshow/slideshow.actions';
import {parseSlideshowDate} from 'apps/pss/src/app/store/slideshow/slideshow-utils';
import {Slideshow} from 'apps/pss/src/app/models/slideshow.model';
import {PSSPlatformEnvironment} from 'apps/pss/src/app/platform-environment';

@Injectable()
export class AppEffects {
  shareDialogSaveSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(ShareDialogActionTypes.SAVE_SUCCESS),
      mergeMap(({uniqueIdentifier, data}: SaveSuccess) => {
        return of(
          addOrUpdateSlideshow(data.slide_show as unknown as ManagerSlideshow, uniqueIdentifier),
          new UpdateSlideshow(parseSlideshowDate(data.slide_show as unknown as Slideshow<number>))
        );
      })
    )
  );

  constructor(private readonly actions: Actions, private readonly platform: PSSPlatformEnvironment) {}
}
