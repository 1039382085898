<div [@openClose]="isOpen ? 'true' : 'false'" (@openClose.done)="onAnimationEvent($event)">
  <div class="reminder-element">
    <div>
      <span>{{ 'PREFERENCES_PAGE.LOGO_MESSAGE_PART_ONE' | translate }}&nbsp;</span>
      <span class="link" routerLink="/preferences">{{ 'PREFERENCES_PAGE.PREFERENCES_LABEL' | translate }}</span>
      <span>&nbsp;</span>
      <span>{{ 'PREFERENCES_PAGE.LOGO_MESSAGE_PART_TWO' | translate }}&nbsp;</span>
      <b *ngIf="!hasLogo">{{ 'GLOBAL.LOGO_LABEL' | translate }}&nbsp;</b>
      <span *ngIf="!hasLogo && !hasWebsite">{{ 'GLOBAL.AND_LABEL' | translate }}&nbsp;</span>
      <b *ngIf="!hasWebsite">{{ 'PREFERENCES_PAGE.LOGO_WEBSITE_LINK' | translate }}&nbsp;</b>
      <span>{{ 'PREFERENCES_PAGE.LOGO_MESSAGE_PART_THREE' | translate }}.</span>
    </div>
    <mds-button variant="fab-circle" color="neutral" type="text" data-qa="close-reminder-button" (click$)="dismiss()">
      <mds-icon icon="pxCloseOutline" />
    </mds-button>
  </div>
</div>
