import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {map, Observable, shareReplay} from 'rxjs';
import {ILayoutService} from './layout-service.interface';

export const MOBILE_LAYOUT_TYPES = [Breakpoints.Handset, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape];
export const TABLET_LAYOUT_TYPES = [Breakpoints.Tablet, Breakpoints.TabletPortrait, Breakpoints.TabletLandscape];
export const DESKTOP_LAYOUT_TYPES = [Breakpoints.Web, Breakpoints.WebPortrait, Breakpoints.WebLandscape];

@Injectable()
export class LayoutService implements ILayoutService {
  isMobile$: Observable<boolean> = this.breakpointObserver.observe(MOBILE_LAYOUT_TYPES).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isTablet$: Observable<boolean> = this.breakpointObserver.observe(TABLET_LAYOUT_TYPES).pipe(
    map(result => result.matches),
    shareReplay(1)
  );
  isDesktop$: Observable<boolean> = this.breakpointObserver.observe(DESKTOP_LAYOUT_TYPES).pipe(
    map(result => result.matches),
    shareReplay(1)
  );

  constructor(private readonly breakpointObserver: BreakpointObserver) {}
}
