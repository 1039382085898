import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {CheckoutSession} from '../entities/checkout-session';
import {map, Observable} from 'rxjs';
import {ICheckoutSessionParams} from '../entities/checkout-session-params';
import {IBillingCheckoutSessionProvider} from '../entities/billing-checkout-session-provider';

@Injectable()
export class BillingCheckoutSessionService implements IBillingCheckoutSessionProvider {
  private readonly createBillingCheckoutSessionMutation = gql<
    {createBillingCheckoutNewSession: {session: CheckoutSession}},
    ICheckoutSessionParams
  >`
    mutation CreateBillingCheckoutNewSession(
      $priceId: String!
      $redirectUrl: String!
      $cancelUrl: String!
      $quantity: Int
      $couponCodes: [String]
    ) {
      createBillingCheckoutNewSession(
        priceId: $priceId
        redirectUrl: $redirectUrl
        cancelUrl: $cancelUrl
        quantity: $quantity
        couponCodes: $couponCodes
      ) {
        session
      }
    }
  `;

  constructor(private readonly apollo: Apollo) {}

  //NOTE: jwt is not required because request can be intercepted in app level where authorization header will be attached
  createCheckoutSession(params: ICheckoutSessionParams, jwtToken?: string): Observable<CheckoutSession> {
    const options = {
      mutation: this.createBillingCheckoutSessionMutation,
      variables: {...params},
      context: jwtToken ? {headers: {authorization: `bearer ${jwtToken}`}} : {},
    };

    return this.apollo.mutate(options).pipe(
      map(response => {
        if (response?.data?.createBillingCheckoutNewSession) {
          return response.data.createBillingCheckoutNewSession.session;
        }
        throw new Error('cant create session');
      })
    );
  }
}
