import {inject, Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {ImageCroppingRatio} from '../enums/image-cropping-ratio';
import {filter, map, Observable} from 'rxjs';
import {IPhotoCroppingGql, IPhotoCroppingGqlWithPicId} from '../entities/photo-cropping-gql';
import {omit} from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class CroppingDataGqlService {
  private readonly apollo = inject(Apollo);

  private readonly getImageCroppingDataByPicIdQuery = gql<
    {picImagesTest: IPhotoCroppingGqlWithPicId[]},
    {picImageIds: number[]; ratio: ImageCroppingRatio}
  >`
    query GetImageCroppingDataByPicId($picImageIds: [Int!]!, $ratio: ImageCroppingRatio!) {
      picImagesTest(picIds: $picImageIds) {
        picId
        croppingData(ratio: $ratio) {
          width
          height
          x
          y
        }
      }
    }
  `;

  private readonly getImageCroppingDataQuery = gql<
    {ownImages: IPhotoCroppingGql[]},
    {imageIds: string[]; ratio: ImageCroppingRatio}
  >`
    query GetImageCroppingData($imageIds: [String!]!, $ratio: ImageCroppingRatio!) {
      ownImages(imageIds: $imageIds) {
        id
        croppingData(ratio: $ratio) {
          width
          height
          x
          y
        }
      }
    }
  `;

  getImageCroppingDataByPicId$(
    picImageIds: number[],
    ratio: ImageCroppingRatio
  ): Observable<IPhotoCroppingGqlWithPicId[]> {
    return this.apollo
      .query({
        query: this.getImageCroppingDataByPicIdQuery,
        variables: {picImageIds, ratio},
      })
      .pipe(
        filter(result => !!result.data),
        map(result => result.data.picImagesTest)
      );
  }

  getImageCroppingData$(imageIds: string[], ratio: ImageCroppingRatio): Observable<IPhotoCroppingGql[]> {
    return this.apollo
      .query({
        query: this.getImageCroppingDataQuery,
        variables: {imageIds, ratio},
      })
      .pipe(
        filter(result => !!result.data),
        map(result =>
          result.data.ownImages.map(
            i => ({id: i.id, croppingData: omit(['__typename'], i.croppingData)}) as IPhotoCroppingGql
          )
        )
      );
  }
}
