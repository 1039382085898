import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {IBillingProduct} from '../entities/billing-product';
import {IBillingProductAdapter} from '../entities/billing-product-adapter';
import {IBillingProductClient} from '../entities/billing-product-client';
import {IBillingProductClientFeature} from '../entities/billing-product-client-feature';
import {BillingPeriod} from '../enums/billing-period';
import {BillingPeriodUnit} from '../enums/billing-period-unit';

@Injectable()
export class BillingProductAdapterService implements IBillingProductAdapter {
  private readonly SORTED_METADATA_KEYS: string[] = [
    'PSS_SLIDESHOW_COUNT',
    'PSF_GALLERIES_COUNT',
    'PSS_VIDEO_4K_ALLOWED',
    'PSS_VIDEO_DOWNLOAD_PHOTOGRAPHER_ENABLED',
    'PSS_VIDEO_DOWNLOAD_CLIENT_ENABLED',
    'PSS_GA_CODE_ALLOWED',
    'PSF_GA_CODE_ALLOWED',
    'PRODUCT_ACCESS',
    'PSS_VIDEO_LIMIT',
    'PSS_VIDEO_WATERMARK',
    'PSS_IS_TRIAL',
    'PSF_IS_TRIAL',
  ];

  private readonly PRODUCT_INFO: Record<string, {prefix?: string; maxPlan?: string; name: string}> = {
    [ProductFamily.PSS]: {
      prefix: 'PSS_',
      name: 'SmartSlides',
      maxPlan: 'Pixellu SmartSlides Unlimited',
    },
    [ProductFamily.PSF]: {
      prefix: 'PSF_',
      name: 'Galleries',
      maxPlan: 'Pixellu Galleries Unlimited',
    },
    [ProductFamily.SA]: {
      name: 'SmartAlbums Plus',
    },
  };
  constructor(private translate: TranslateService) {}

  private getValue(raw: string): boolean | number | null {
    const isNumber = !isNaN(Number(raw));

    if (isNumber) {
      const numb = Number(raw);
      if (numb > 1) {
        return numb;
      }
    }

    if (raw === '-1') {
      return 9999; // NOTE: messageformat doesn't match =-1;
    }

    if (raw === '0') {
      return false;
    }

    if (raw === '1') {
      return true;
    }

    return null;
  }

  private getBundleFeatures(
    metadata: Record<string, string>,
    originalProductFamily: ProductFamily
  ): IBillingProductClientFeature[] {
    const availableProducts = metadata['PRODUCT_ACCESS'].split(',');
    const bundleFeatures: IBillingProductClientFeature[] = [];

    for (const prod of availableProducts) {
      const productFamily = prod.toUpperCase() as ProductFamily;
      if (!this.PRODUCT_INFO[productFamily]) continue;

      const bundleFeature = this.createBundleFeature(
        productFamily,
        metadata,
        originalProductFamily,
        this.PRODUCT_INFO[productFamily]
      );

      bundleFeatures.push(bundleFeature);
    }

    return this.sortFeaturesByProductInfo(bundleFeatures, this.PRODUCT_INFO[originalProductFamily]);
  }

  private getFeatures(metadata: Record<string, string>): IBillingProductClientFeature[] {
    const features: IBillingProductClientFeature[] = [];
    const sortedMetadata = this.sortMetadata(metadata, this.SORTED_METADATA_KEYS);
    const filteredMetadata = this.removeHDIf4KPresent(sortedMetadata);

    for (const key in filteredMetadata) {
      const feature: IBillingProductClientFeature = {parts: []};

      if (key === 'PRODUCT_ACCESS') {
        const products = filteredMetadata[key].split(',');

        products.forEach(p => {
          const feature: IBillingProductClientFeature = {
            parts: [
              {
                text: this.translate.instant(key, {product: p}),
              },
            ],
          };

          if (feature.parts[0].text && feature.parts[0].text !== key) {
            features.push(feature);
          }
        });

        continue;
      }

      const value = this.getValue(filteredMetadata[key]);

      if (value !== null && value !== false) {
        const part = {
          text: this.translate.instant(key, typeof value === 'number' ? {count: value} : {}),
        };

        if (part.text && part.text !== key) {
          feature.parts.push(part);
        }
      }

      features.push(feature);
    }

    return features.filter(f => f.parts.length);
  }

  private getPeriod(product: IBillingProduct): BillingPeriod | null {
    if (
      (product.period === 1 && product.periodUnit === BillingPeriodUnit.YEAR) ||
      (product.period === 4 && product.periodUnit === BillingPeriodUnit.DAY) //NOTE: for subs update testing purposes
    ) {
      return BillingPeriod.YEARLY;
    }

    if (
      (product.period === 1 && product.periodUnit === BillingPeriodUnit.MONTH) ||
      (product.period === 3 && product.periodUnit === BillingPeriodUnit.DAY) //NOTE: for subs update testing purposes
    ) {
      return BillingPeriod.MONTHLY;
    }

    return null;
  }

  private getPrice(billingProduct: IBillingProduct): number {
    return billingProduct.price / 100;
  }

  private getPromo(
    billingProduct: IBillingProduct,
    highlightProduct: string | null = null,
    highlightProductTitle: string | null = null
  ): string | null {
    if (highlightProduct) {
      return billingProduct.product.id === highlightProduct ? highlightProductTitle : null;
    }

    // default is bundle
    return billingProduct.billingProductFamily.id === ProductFamily.BUNDLE
      ? this.translate.instant('Bundle and save')
      : null;
  }

  sortFeaturesByProductInfo(
    bundleFeatures: IBillingProductClientFeature[],
    targetProduct: {prefix?: string; maxPlan?: string; name: string}
  ): IBillingProductClientFeature[] {
    return bundleFeatures.sort((a, b) => {
      if (a.parts[0].text === targetProduct.name) {
        return -1;
      } else if (b.parts[0].text === targetProduct.name) {
        return 1;
      }
      return 0;
    });
  }

  filterMetadataByPrefix(
    metadata: Record<string, string>,
    initialMetadata: Record<string, string> = {},
    prefix: string | undefined
  ): Record<string, string> {
    return Object.entries(metadata)
      .filter(([key]) => prefix && key.startsWith(prefix))
      .reduce((acc, [key, value]) => ({...acc, [key]: value}), initialMetadata);
  }

  sortMetadata(metadata: Record<string, string>, sortedMetadataKeys: string[]): Record<string, string> {
    const sortedMetadata: Record<string, string> = {};

    sortedMetadataKeys.forEach(key => {
      if (key in metadata) {
        sortedMetadata[key] = metadata[key];
      }
    });

    for (const key in metadata) {
      if (!(key in sortedMetadata)) {
        sortedMetadata[key] = metadata[key];
      }
    }

    return sortedMetadata;
  }

  createBundleFeature(
    productFamily: ProductFamily,
    metadata: Record<string, string>,
    originalProductFamily: ProductFamily,
    productFamilyInfo: {prefix?: string; maxPlan?: string; name: string}
  ): IBillingProductClientFeature {
    const {prefix, name, maxPlan} = productFamilyInfo;
    const initialMetadata: Record<string, string> = !prefix ? {PRODUCT_ACCESS: 'sa,cp'} : {};
    const filteredMetadata = this.filterMetadataByPrefix(metadata, initialMetadata, prefix);
    const hint =
      originalProductFamily === productFamily
        ? []
        : this.getFeatures(filteredMetadata).map(f => f.parts.map(p => p.text).join(' '));

    return {
      parts: maxPlan
        ? [{text: this.translate.instant(name)}, {text: this.translate.instant(maxPlan), hint}]
        : [{text: this.translate.instant(name), hint}],
    };
  }

  transformToClient(
    billingProduct: IBillingProduct,
    originalProductFamily: ProductFamily,
    highlightProduct: string | null = null,
    highlightProductTitle: string | null = null
  ): IBillingProductClient | null {
    const period = this.getPeriod(billingProduct);
    const price = this.getPrice(billingProduct);
    if (period !== null) {
      return {
        id: billingProduct.id,
        name: this.translate.instant(billingProduct.product.name),
        priceMonthly: period === BillingPeriod.YEARLY ? Math.floor(price / 12) : price,
        priceYearly: period === BillingPeriod.YEARLY ? price : price * 12,
        period,
        features:
          billingProduct.billingProductFamily.id === ProductFamily.BUNDLE
            ? this.getBundleFeatures(billingProduct.product.metaData, originalProductFamily)
            : this.getFeatures(billingProduct.product.metaData),
        promo: this.getPromo(billingProduct, highlightProduct, highlightProductTitle),
        currencyCode: billingProduct.currencyCode,
        productId: billingProduct.product.id,
        productFamily: billingProduct.billingProductFamily.id,
        productName: billingProduct.product.name,
      };
    }
    return null;
  }

  removeHDIf4KPresent(metadata: Record<string, string>): Record<string, string> {
    const has4KVideo = this.getValue(metadata['PSS_VIDEO_4K_ALLOWED']);
    const hasHDVideo = this.getValue(metadata['PSS_VIDEO_DOWNLOAD_PHOTOGRAPHER_ENABLED']);
    if (has4KVideo && hasHDVideo) {
      const newMetadata = {...metadata};
      delete newMetadata['PSS_VIDEO_DOWNLOAD_PHOTOGRAPHER_ENABLED'];
      return newMetadata;
    }
    return metadata;
  }
}
