import {NgModule} from '@angular/core';
import {RudderstackModule} from '@px/shared/data-access/rudderstack';
import {MixpanelModule} from '@px/shared-data-access-mixpanel';
import {TrackingService} from './services/tracking.service';

@NgModule({
  imports: [RudderstackModule, MixpanelModule],
  providers: [TrackingService],
})
export class FeatureTrackingModule {}
