import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {KeyValue} from '@angular/common';
import {SortTypeEnum} from '../../enums/sort-type.enum';
import {IManagerSortTypeList} from '../../interfaces/manager-sort-type-list.interface';
import {SORT_TYPE_LIST} from '../../tokens/sort-type-list.token';
import {IManagerSortType} from '../../interfaces/manager-sort-type.interface';

@Component({
  selector: 'px-manager-sort',
  templateUrl: './manager-sort.component.html',
  styleUrls: ['./manager-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerSortComponent {
  @Input() currentSortType?: SortTypeEnum;
  @Output() sort$ = new EventEmitter<SortTypeEnum>();

  constructor(@Inject(SORT_TYPE_LIST) readonly list: IManagerSortTypeList) {}

  originalOrder(a: KeyValue<string, IManagerSortType>, b: KeyValue<string, IManagerSortType>): number {
    return a.value.order - b.value.order;
  }

  sortSelect(key: string): void {
    this.sort$.emit(key as SortTypeEnum);
  }
}
