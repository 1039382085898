import {ConnectedPosition, Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  booleanAttribute,
  inject,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PUI_OVERLAY_DEFAULT_DELAY} from '@pui/components/overlay';
import {delay, fromEvent, of, switchMap, takeUntil} from 'rxjs';
import {
  MDS_TOOLTIP_POSITION_OFFSET,
  puiTooltipData,
  puiTooltipPosition,
  puiTooltipType,
  puiTooltipWidth,
} from './tooltip.types';
import {PuiTooltipComponent} from './tooltip/tooltip.component';

@UntilDestroy()
@Directive({
  selector: '[puiTooltip]',
  standalone: true,
  exportAs: 'puiTooltip',
})
export class PuiTooltipDirective implements OnInit, OnChanges, OnDestroy {
  private readonly overlay = inject(Overlay);
  private readonly elementRef = inject(ElementRef);
  private readonly cdr = inject(ChangeDetectorRef);

  private overlayRef?: OverlayRef;
  private componentRef?: ComponentRef<PuiTooltipComponent>;

  @Input('puiTooltip') tooltip?: puiTooltipData;
  @Input('puiTooltipPosition') position: puiTooltipPosition = 'top';
  @Input('puiTooltipType') type: puiTooltipType = 'base';
  @Input('puiTooltipWidth') width: puiTooltipWidth = 'medium';
  @Input('puiTooltipShowDelay') showDelay = 0;
  @Input('puiTooltipHideDelay') hideDelay = 0;
  @Input({alias: 'puiTooltipOpened', transform: booleanAttribute}) opened = false;
  @Input({alias: 'puiTooltipDisabled', transform: booleanAttribute}) disabled = false;
  @Input({alias: 'puiTooltipWrapBalancer', transform: booleanAttribute}) wrapBalancer = false;
  @Input({alias: 'puiTooltipInside', transform: booleanAttribute}) inside = false;

  private get showDelayValue(): number {
    return this.showDelay ?? PUI_OVERLAY_DEFAULT_DELAY;
  }

  private get hideDelayValue(): number {
    return this.hideDelay ?? PUI_OVERLAY_DEFAULT_DELAY;
  }

  private createEventListeners(): void {
    const mouseEnter$ = fromEvent(this.elementRef.nativeElement, 'mouseenter');
    const mouseLeave$ = fromEvent(this.elementRef.nativeElement, 'mouseleave');

    mouseEnter$
      .pipe(
        switchMap(e => of((e as MouseEvent).type).pipe(delay(this.showDelayValue), takeUntil(mouseLeave$))),
        untilDestroyed(this)
      )
      .subscribe(() => this.show());
    mouseLeave$
      .pipe(
        switchMap(e => of((e as MouseEvent).type).pipe(delay(this.hideDelayValue), takeUntil(mouseEnter$))),
        untilDestroyed(this)
      )
      .subscribe(() => this.hide());
  }

  private attach(): void {
    if (this.overlayRef) return;
    this.opened = true;
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const portal = new ComponentPortal(PuiTooltipComponent);
    this.componentRef = this.overlayRef?.attach(portal);
    this.overlayRef.updatePosition();
    this.componentRef.instance.tooltip = this.tooltip;
    this.componentRef.instance.type = this.type;
    this.componentRef.instance.width = this.width;
    this.componentRef.instance.wrapBalancer = this.wrapBalancer;
    this.componentRef.instance.resize$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.overlayRef?.updatePosition();
    });
    // this.initTriggerHover(this.overlayRef.overlayElement);
    this.componentRef.instance.markForCheck();
  }

  private detach(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
    this.opened = false;
  }

  private getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: false,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.inside ? this.getInnerPositions() : this.getPositions())
        .withTransformOriginOn('.tooltip'),
    });
  }

  private getPositions(): ConnectedPosition[] {
    const offset = MDS_TOOLTIP_POSITION_OFFSET;
    let positions: ConnectedPosition[] = [];

    const positionTop: ConnectedPosition = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -offset,
    };

    const positionRight: ConnectedPosition = {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: offset,
    };

    const positionRightTop: ConnectedPosition = {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: offset,
    };

    const positionRightBottom: ConnectedPosition = {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: offset,
    };

    const positionLeftTop: ConnectedPosition = {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -offset,
    };

    const positionLeftBottom: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: -offset,
    };

    const positionBottom: ConnectedPosition = {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: offset,
    };

    const positionLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -offset,
    };

    const positionBottomRight: ConnectedPosition = {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: offset,
    };

    const positionBottomLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: offset,
    };

    const positionTopLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -offset,
    };

    const positionTopRight: ConnectedPosition = {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetY: -offset,
    };

    switch (this.position) {
      case 'top':
        positions = [positionTop, positionBottom];
        break;
      case 'topLeft':
        positions = [positionTopLeft, positionBottom];
        break;
      case 'topRight':
        positions = [positionTopRight, positionBottom];
        break;
      case 'right':
        positions = [positionRight, positionLeft];
        break;
      case 'rightTop':
        positions = [positionRightTop, positionLeftTop];
        break;
      case 'rightBottom':
        positions = [positionRightBottom, positionLeftBottom];
        break;
      case 'bottom':
        positions = [positionBottom, positionTop];
        break;
      case 'bottomRight':
        positions = [positionBottomRight, positionTopRight];
        break;
      case 'bottomLeft':
        positions = [positionBottomLeft, positionTopLeft];
        break;
      case 'left':
        positions = [positionLeft, positionRight];
        break;
      case 'leftTop':
        positions = [positionLeftTop, positionRightTop];
        break;
      case 'leftBottom':
        positions = [positionLeftBottom, positionRightBottom];
        break;
      default:
        break;
    }

    return positions;
  }

  private getInnerPositions(): ConnectedPosition[] {
    const offset = MDS_TOOLTIP_POSITION_OFFSET;
    let positions: ConnectedPosition[] = [];

    const positionTop: ConnectedPosition = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: offset,
    };

    const positionBottom: ConnectedPosition = {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -offset,
    };

    const positionRight: ConnectedPosition = {
      originX: 'end',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -offset,
    };

    const positionLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: offset,
    };

    const positionTopLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: offset,
      offsetY: offset,
    };

    const positionTopRight: ConnectedPosition = {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -offset,
      offsetY: offset,
    };

    const positionBottomLeft: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: offset,
      offsetY: -offset,
    };

    const positionBottomRight: ConnectedPosition = {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: -offset,
      offsetY: -offset,
    };

    const positionRightTop: ConnectedPosition = {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -offset,
      offsetY: offset,
    };

    const positionRightBottom: ConnectedPosition = {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: -offset,
      offsetY: -offset,
    };

    const positionLeftTop: ConnectedPosition = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: offset,
      offsetY: offset,
    };

    const positionLeftBottom: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: offset,
      offsetY: -offset,
    };

    const positionCenter: ConnectedPosition = {
      originX: 'center',
      originY: 'center',
      overlayX: 'center',
      overlayY: 'center',
    };

    switch (this.position) {
      case 'top':
        positions = [positionTop, positionBottom];
        break;
      case 'topLeft':
        positions = [positionTopLeft, positionBottomLeft];
        break;
      case 'topRight':
        positions = [positionTopRight, positionBottomRight];
        break;
      case 'right':
        positions = [positionRight, positionLeft];
        break;
      case 'rightTop':
        positions = [positionRightTop, positionLeftTop];
        break;
      case 'rightBottom':
        positions = [positionRightBottom, positionLeftBottom];
        break;
      case 'bottom':
        positions = [positionBottom, positionTop];
        break;
      case 'bottomRight':
        positions = [positionBottomRight, positionTopRight];
        break;
      case 'bottomLeft':
        positions = [positionBottomLeft, positionTopLeft];
        break;
      case 'left':
        positions = [positionLeft, positionRight];
        break;
      case 'leftTop':
        positions = [positionLeftTop, positionRightTop];
        break;
      case 'leftBottom':
        positions = [positionLeftBottom, positionRightBottom];
        break;
      case 'center':
        positions = [positionCenter];
        break;
      default:
        positions = [positionCenter];
        break;
    }

    return positions;
  }

  ngOnInit(): void {
    this.createEventListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.hide();
    }
    if (changes['tooltip']) {
      if (!this.tooltip) {
        this.hide();
      } else {
        if (this.componentRef) {
          this.componentRef.instance.tooltip = this.tooltip;
          this.componentRef.instance.markForCheck();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.detach();
  }

  show(): void {
    if (this.disabled || !this.tooltip) return;
    if (this.opened && this.componentRef?.instance.animationState === 'exit') {
      this.componentRef?.instance.startOpenAnimation();

      return;
    }
    this.attach();
  }

  hide(): void {
    if (!this.opened) return;
    this.componentRef?.instance.animationStateChanged$.pipe(untilDestroyed(this, 'show')).subscribe(event => {
      if (event.phaseName === 'done' && event.toState === 'exit') {
        this.detach();
      }
    });
    this.componentRef?.instance.startExitAnimation();
  }

  toggle(): void {
    if (this.opened) {
      this.hide();
    } else {
      this.show();
    }
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }
}
