import {IBillingProductClient} from '@ps/pricing-domain';

export interface ISubscriptionServiceCheckoutSuccessEventData {
  billingProductClient: IBillingProductClient;
}
export interface ISubscriptionServiceCheckoutFailEventData {
  error: Error;
}

export interface ISubscriptionServiceCheckoutOpenEventData {
  billingProductClient: IBillingProductClient;
}
