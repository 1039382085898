import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MdsAppLoaderService} from '@mds/components/app-loader';
import {TranslateModule} from '@ngx-translate/core';
import {FeaturePageMobileNotSupportedModule} from '@px/legacy/feature-page-mobile-not-supported';
import {PSSPlatformEnvironment} from '../../../platform-environment';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  standalone: true,
  imports: [FeaturePageMobileNotSupportedModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MdsAppLoaderService],
})
export class MobileComponent implements OnInit {
  private readonly mdsAppLoaderService = inject(MdsAppLoaderService);
  readonly platformEnvironment = inject(PSSPlatformEnvironment);

  ngOnInit(): void {
    this.mdsAppLoaderService.completeLoading();
  }
}
