import {Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslationService} from 'apps/pss/src/app/shared/services/translations.service';
import {GoogleAnalyticsService} from '../../auth/services/google-analytics.service';
import {PSSPlatformEnvironment} from '../../platform-environment';
import {ConnectionStatusFacadeService} from '@px/connection-issue/domain';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {GlobalState} from '../../store/global/global.state';
import {ConnectionOffline, ConnectionOnline} from '../../store/global/global.actions';
import {FeatureFlagBodyClassService} from '@px/shared/utils/feature-flag-body-class';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  private readonly translate = inject(TranslationService);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly platform = inject(PSSPlatformEnvironment);
  private readonly connectionStatusFacadeService = inject(ConnectionStatusFacadeService);
  private readonly store$ = inject(Store<GlobalState>);
  private readonly featureFlagBodyClassService = inject(FeatureFlagBodyClassService);

  ngOnInit(): void {
    if (this.platform.GA_ENABLED) {
      this.googleAnalyticsService.init(this.platform.GA_TRACKING_ID);
    }

    this.translate.init();
    this.connectionStatusFacadeService.initialize();
    this.connectionStatusFacadeService.hasConnection$.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      if (status) {
        this.store$.dispatch(new ConnectionOnline());
        return;
      }

      this.store$.dispatch(new ConnectionOffline());
    });
    this.featureFlagBodyClassService.init();
  }
}
