import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BillingEndDatePipe} from './pipes/billing-end-date.pipe';
import {FramesToMsPipe} from './pipes/frames-to-ms.pipe';
import {FramesToSPipe} from './pipes/frames-to-s.pipe';
import {ProgressBytesPipe} from './pipes/progress-bytes.pipe';
import {RoundSecondsPipe} from './pipes/round-seconds.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [BillingEndDatePipe, FramesToMsPipe, FramesToSPipe, ProgressBytesPipe, RoundSecondsPipe],
  declarations: [BillingEndDatePipe, FramesToMsPipe, FramesToSPipe, ProgressBytesPipe, RoundSecondsPipe],
  exports: [BillingEndDatePipe, FramesToMsPipe, FramesToSPipe, ProgressBytesPipe, RoundSecondsPipe],
})
export class SharedFormattersModule {}
