import {inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EventDateFormatOverrides} from '@px/shared/env';

@Pipe({
  name: 'localizedDate',
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  private translate = inject(TranslateService);
  private locale = inject(LOCALE_ID);

  transform(value: number, formatOverrides: EventDateFormatOverrides, separator?: string): string {
    value = value.toString().length === 10 ? value * 1000 : value;

    let date = new Date(value);

    const timezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - timezoneOffset);

    const dateLocale = this.getLocale();
    const options = this.getOptions(dateLocale, formatOverrides);
    const localisedDate = date.toLocaleDateString(dateLocale, options);

    return separator ? localisedDate.replace(/[\s.,/-]/g, separator) : localisedDate;
  }

  getLocale(): string | undefined {
    const currentLocale = this.translate.getBrowserCultureLang()?.includes(this.translate.currentLang)
      ? this.translate.getBrowserCultureLang()
      : this.translate.currentLang;

    return currentLocale && this.locale.includes(currentLocale) ? this.locale : currentLocale;
  }

  getOptions(
    locale: string | undefined,
    formatOverrides?: EventDateFormatOverrides
  ): Partial<Intl.DateTimeFormatOptions> {
    if (locale && formatOverrides) {
      const baseLocale = locale.split('-')[0];
      const formatOverridesMap = new Map(Object.entries(formatOverrides));

      if (locale && formatOverridesMap.has(locale)) {
        return formatOverridesMap.get(locale) as Partial<Intl.DateTimeFormatOptions>;
      }

      if (baseLocale && formatOverridesMap.has(baseLocale)) {
        return formatOverridesMap.get(baseLocale) as Partial<Intl.DateTimeFormatOptions>;
      }
    }

    return {};
  }
}
