import {error} from './error';

export class AbTestForRealUser {
  private _versions: string[] = [];
  private _chosenVersion: string;

  constructor(versions: string[], chosenVersion: string) {
    this._versions = versions;
    this._chosenVersion = chosenVersion;
  }

  getVersion(): string {
    return this._chosenVersion;
  }

  setVersion(version: string) {
    if (this._versions.indexOf(version) === -1) {
      error('Version <' + version + '> has not been declared: [ ' + this._versions.join(', ') + ' ]');
    }
    this._chosenVersion = version;
  }

  shouldRender(versions: string[]): boolean {
    for (const version of versions) {
      if (this._versions.indexOf(version) === -1) {
        error('Version <' + version + '> has not been declared: [ ' + this._versions.join(', ') + ' ]');
      }
    }
    return versions.indexOf(this._chosenVersion) !== -1;
  }
}

export class RandomExtractor {
  private _weights: [number, string][];
  private _versions: string[];

  setWeights(weights: [number, string][]) {
    this._weights = weights;
  }

  setVersions(versions: string[]) {
    this._versions = versions;
  }

  run(): string {
    if (this._weights.length === 0) {
      return this._versions[Math.floor(Math.random() * this._versions.length)];
    }
    const random: number = Math.random() * 100;
    for (const weight of this._weights) {
      if (random <= weight[0]) {
        return weight[1];
      }
    }
    return this._versions[0];
  }
}

export class CookieHandler {
  get(name: string): string {
    name = encodeURIComponent(name);
    const regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
    const results = regexp.exec(document.cookie);
    return !results ? '' : decodeURIComponent(results[1]);
  }

  set(name: string, value: string, domain?: string, expires?: number) {
    let cookieStr = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';
    if (expires) {
      const dtExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
      cookieStr += 'expires=' + dtExpires.toUTCString() + ';';
    }
    if (domain) {
      cookieStr += 'domain=' + domain + ';';
    }
    document.cookie = cookieStr;
  }
}
