import {booleanAttribute, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {pxValueAttribute} from '@pui/cdk/core';

export type IMdsSkeletonBorderRadius = 'default' | 'rounded' | 'circle';

@Component({
  selector: 'mds-skeleton-bone',
  standalone: true,
  templateUrl: './skeleton-bone.component.html',
  styleUrls: ['./skeleton-bone.component.scss'],
  host: {
    '[style.width]': 'width',
    '[style.height]': 'height',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsSkeletonBoneComponent {
  @Input() borderRadius: IMdsSkeletonBorderRadius = 'default';
  @Input({transform: pxValueAttribute}) width?: number | string;
  @Input({transform: pxValueAttribute}) height?: number | string;
  @Input({transform: booleanAttribute}) animated = false;
}
