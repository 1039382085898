import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../store/app/app.state';
import {Observable, of} from 'rxjs';
import {AuthSelectors} from '../auth/store/auth.selectors';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import * as AuthActions from '../auth/store/auth.actions';
import {AuthGuard} from '@px/photographer-auth/feature-auth';

@Injectable()
export class CheckAuthGuard {
  get authStatus$(): Observable<boolean> {
    return this.store$.pipe(
      select(AuthSelectors.getLoggedIn),
      tap((isAuthenticated: boolean | null) => {
        if (isAuthenticated === null) {
          this.store$.dispatch(AuthActions.LogInCheck());
        }
      }),
      filter((isAuthenticated: boolean | null) => isAuthenticated !== null),
      take(1)
    );
  }

  constructor(
    private store$: Store<AppState>,
    private readonly authGuard: AuthGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(route, state).pipe(
      switchMap(state => {
        return state
          ? this.authStatus$.pipe(
              switchMap(isAuthenticated =>
                of(isAuthenticated).pipe(
                  tap(() => !isAuthenticated && this.store$.dispatch(AuthActions.LogInRedirect()))
                )
              )
            )
          : of(false);
      })
    );
  }
}
