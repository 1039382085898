import {Injectable} from '@angular/core';
import {Languages} from '@px/shared/env';
import {FramesToSPipe} from '@px/shared/formatters';
import {TranslateService} from '@ngx-translate/core';
import {AudioCategoryPipe, AudioEnergyPipe, TempoPipe} from '@px/pss-feature-audio-browser';
import {TrackingProperties} from '@px/shared/user-tracking';
import {SlideShowState} from '../../store/slideshow/slideshow.state';
import {Preferences} from '../../models/preferences.model';
import {ExtractField} from '../../core/interfaces/extract-field';
import {Segment} from '../../models/slideshow.model';
import {getSlideshowPreviewUrl} from '../../store/slideshow/slideshow-utils';
import {IPSSAudio} from '../../models/pss-audio.model';
import {IPSSTemplate} from '../../models/pss-template';
import {ITrackingInfo} from './tracking-info-token';

@Injectable()
export class TrackingInfoService implements ITrackingInfo {
  protected get translations(): {[name: string]: () => string} {
    return this.translateService.translations[Languages.en] ?? {};
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly framesToS: FramesToSPipe,
    private readonly audioEnergyPipe: AudioEnergyPipe,
    private readonly audioCategoryPipe: AudioCategoryPipe,
    private readonly tempoPipe: TempoPipe
  ) {}

  slideshowInfo(slideshow: SlideShowState, preferences: Preferences): TrackingProperties {
    const {
      segmentList: {entities},
    } = slideshow;
    const ids: string[] = slideshow.segmentList.ids as unknown[] as string[];
    const bmSegmentsCount: number = ids.reduce(
      (count: number, id: ExtractField<Segment, 'id'>) => (entities[id].is_beatmatching ? count + 1 : count),
      0
    );
    const slideshowLength: number = this.framesToS.transform(
      ids.reduce((acc, id) => acc + entities[id].audio_end - entities[id].audio_start, 0)
    );

    return {
      'Slideshow UID': slideshow.unique_identifier,
      'Slideshow URL': getSlideshowPreviewUrl(slideshow.slug, preferences),
      'Total length': slideshowLength,
      'Total Images': slideshow.photoList.ids.length,
      'Total segments': ids.length,
      'Contains beat-matched segment': bmSegmentsCount > 0,
      'All segments beat-matched': bmSegmentsCount === ids.length,
      'Total unique songs': new Set(ids.map(id => entities[id].audio.id)).size,
      Password: !!slideshow.common_data.password,
      'Seconds per slide': Math.floor(slideshowLength / slideshow.photoList.ids.length),
      'Video download': slideshow.common_data.is_download_button,
      'Video download pin': slideshow.common_data.is_download_pin,
      'Dynamic action button': slideshow.common_data.call_to_action?.enabled,
    } as TrackingProperties;
  }

  audioInfo(slideshow: SlideShowState, id: string): TrackingProperties {
    const {
      segmentList: {entities},
    } = slideshow;

    const audio = entities[id].audio;
    const category =
      typeof audio.category_id === 'number'
        ? this.audioCategoryPipe.getBaseTranslation(audio.category_id)
        : audio.category;

    const energy =
      typeof audio.energy_id === 'number' ? this.audioEnergyPipe.getBaseTranslation(audio.energy_id) : audio.energy;

    const globalUnknown = this.translations['GLOBAL']?.['UNKNOWN']?.();

    const event: TrackingProperties = {
      'Slideshow UID': slideshow.unique_identifier,
      'Song name': audio.song_title || globalUnknown,
      'Song artist': audio.artist || globalUnknown,
      'Song category': category,
      'Song genre': audio.genre || 'N/A',
      'Song energy': energy || 'N/A',
      'Beat-matched': entities[id].is_beatmatching,
      Favorited: !!audio.is_favorite,
      Vocals: !!audio.lyrics,
    };

    if ('My Uploads' !== audio.category) {
      event['Song provider'] = audio.provider || 'N/A';
      event['Song round'] = audio.round_added || 'N/A';
    }

    return event;
  }

  beatMatchingInfo(
    slideshow: SlideShowState,
    audio: IPSSAudio,
    noErrorPhotosCount: number,
    bmTemplate: IPSSTemplate
  ): TrackingProperties {
    const bmTempoType =
      typeof bmTemplate.tempo_id === 'number'
        ? this.tempoPipe.getBaseTranslation(bmTemplate.tempo_id)
        : bmTemplate.tempo_type;

    const category =
      typeof audio.category_id === 'number'
        ? this.audioCategoryPipe.getBaseTranslation(audio.category_id)
        : audio.category;

    const energy =
      typeof audio.energy_id === 'number' ? this.audioEnergyPipe.getBaseTranslation(audio.energy_id) : audio.energy;

    return {
      'Slideshow UID': slideshow.unique_identifier,
      'Beat-Matching template': bmTempoType,
      'Number of images': noErrorPhotosCount,
      'Song name': audio.song_title,
      'Song artist': audio.artist,
      'Song category': category,
      'Song genre': audio.genre,
      'Song energy': energy,
    } as TrackingProperties;
  }

  audioAddingInfo(source: string, sort?: string): TrackingProperties {
    return {
      Source: source,
      'Sort order': sort ?? 'N/A',
    };
  }

  imageUploadInfo(
    slideshowUId: string,
    segmentId: string | number,
    imagesLength: number,
    isImagesDragged: boolean
  ): TrackingProperties {
    return {
      'Slideshow UID': slideshowUId,
      'Segment ID': segmentId,
      'Number of images': imagesLength,
      'Drag and drop': isImagesDragged,
    };
  }

  imageUploadingErrorInfo(
    slideshowUId: string,
    segmentId: string | number,
    failedImagesLength: number,
    errorType: string,
    fileType: string
  ): TrackingProperties {
    return {
      'Slideshow UID': slideshowUId,
      'Segment ID': segmentId,
      'Number of bad images': failedImagesLength,
      'Error type': errorType,
      'File type': fileType,
    };
  }
}
