import {ThumbnailImageSize} from '../enums/thumbnail-image-size';

export const THUMBNAIL_IMAGE_SIZE_SEARCH_PARAMS: Record<
  ThumbnailImageSize,
  {width: number; height: number; crop: string | null}
> = {
  [ThumbnailImageSize.DEFAULT]: {
    crop: null,
    height: 86,
    width: 86,
  },
  [ThumbnailImageSize.MANAGER_PAGE]: {
    crop: 'fit',
    height: 400,
    width: 600,
  },
  [ThumbnailImageSize.EDITOR_PAGE]: {
    crop: null,
    height: 339,
    width: 339,
  },
};
