import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IBillingProductClient, SubscriptionActivationState} from '@ps/pricing-domain';
import {timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'px-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingDialogComponent {
  @Input() title?: string;
  @Input() description?: string;
  @Input() subscribedProductId?: string;
  @Input() highlightPlan: string | null = null;
  @Input() highlightPlanTitle: string | null = null;
  @Input() highlightPButtonText: string | null = null;
  @Input() sale10 = false;

  @Output() checkoutSuccess$ = new EventEmitter<IBillingProductClient>();
  @Output() checkoutOpen$ = new EventEmitter<IBillingProductClient>();
  @Output() checkoutClosed$ = new EventEmitter<void>();
  @Output() checkoutError$ = new EventEmitter<Error>();
  @Output() upgradeSuccess$ = new EventEmitter<IBillingProductClient>();
  @Output() subscriptionActivationState$ = new EventEmitter<SubscriptionActivationState>();

  @Output() close$ = new EventEmitter<void>();

  hidden = false;

  hide(): void {
    this.hidden = true;
  }

  show(): void {
    this.hidden = false;
  }

  handleCheckoutSuccess(billingProductClient: IBillingProductClient): void {
    timer(50)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.checkoutSuccess$.emit(billingProductClient));
  }

  handleCheckoutClosed(): void {
    this.checkoutClosed$.emit();
    this.show();
  }

  handleCheckoutOpen(billingProduct: IBillingProductClient): void {
    this.checkoutOpen$.emit(billingProduct);
    this.hide();
  }

  handleUpgradeSuccess(billingProductClient: IBillingProductClient): void {
    this.upgradeSuccess$.emit(billingProductClient);
  }
}
