import {ChangeDetectionStrategy, Component, EventEmitter, inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {SubscriptionActivationWaitingFacade} from '@px/subscription-manager/domain';
import {IMdsDialogConfig, MDS_DIALOG_DATA, MdsDialogRef} from '@pui/components/dialog';
import {ISubscriptionManagerDialogData} from '../entities/subscription-manager-dialog-data';
import {IBillingProductClient, SubscriptionActivationState} from '@ps/pricing-domain';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './subscription-manager-dialog.component.html',
  styleUrls: ['./subscription-manager-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionManagerDialogComponent implements OnDestroy {
  private readonly MdsDialogRef: MdsDialogRef<SubscriptionManagerDialogComponent, void> = inject(MdsDialogRef);

  readonly subscriptionActivationWaitingFacade = inject(SubscriptionActivationWaitingFacade);

  readonly dialogData = inject<IMdsDialogConfig<ISubscriptionManagerDialogData>>(MDS_DIALOG_DATA);

  checkoutSuccess$ = new EventEmitter<IBillingProductClient>();

  checkoutError$ = new EventEmitter<Error>();
  checkoutOpen$ = new EventEmitter<IBillingProductClient>();
  upgradeSuccess$ = new EventEmitter<IBillingProductClient>();
  subscriptionActivationState$ = new EventEmitter<SubscriptionActivationState>();
  subscriptionActivatedActionClick$ = new EventEmitter<void>();

  get subscribedProductId(): string | undefined {
    return this.dialogData.data?.subscribedProductId;
  }

  get title(): string | undefined {
    return this.dialogData.data?.title;
  }

  get description(): string | undefined {
    return this.dialogData.data?.description;
  }

  get highlightPlan(): string | null {
    return this.dialogData.data?.highlightPlan ?? null;
  }

  get sale10(): boolean {
    return this.dialogData.data?.sale10 ?? false;
  }

  get highlightPlanTitle(): string | null {
    return this.dialogData.data?.highlightPlanTitle ?? null;
  }

  get highlightPButtonText(): string | null {
    return this.dialogData.data?.highlightPButtonText ?? null;
  }

  close(): void {
    this.MdsDialogRef.close();
  }

  handleCheckoutSuccess(billingProductClient: IBillingProductClient): void {
    this.checkoutSuccess$.emit(billingProductClient);
  }

  handleCheckoutOpen$(billingProductClient: IBillingProductClient): void {
    this.checkoutOpen$.emit(billingProductClient);
  }

  handleCheckoutError(e: Error): void {
    console.error(e);
    this.checkoutError$.emit(e);
  }

  handleUpgradeSuccess(billingProductClient: IBillingProductClient): void {
    this.upgradeSuccess$.emit(billingProductClient);
  }

  handleSubscriptionActivatedActionClick(): void {
    this.subscriptionActivatedActionClick$.emit();
  }

  handleSubscriptionActivationState(subscriptionActivationState: SubscriptionActivationState): void {
    this.subscriptionActivationState$.emit(subscriptionActivationState);
    this.subscriptionActivationWaitingFacade
      .handleSubscriptionActivationState(subscriptionActivationState)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptionActivationWaitingFacade.reset();
  }
}
