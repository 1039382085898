import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'mds-wrap-balancer',
  standalone: true,
  styleUrls: ['./wrap-balancer.component.scss'],
  templateUrl: './wrap-balancer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdsWrapBalancerComponent {}
