import {inject, Injectable} from '@angular/core';
import {asyncScheduler, BehaviorSubject, catchError, EMPTY, finalize, Subject, takeUntil} from 'rxjs';
import {CustomerSubscriptionsFacadeService} from './customer-subscriptions-facade.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SubscriptionActivationState} from '../entities/subscription-activation-state';
import {ISubscriptionActivationFacade} from '../entities/subscription-activation-facade.interface';
import {IBillingProductClient} from '../entities/billing-product-client';

@UntilDestroy()
@Injectable()
export class SubscriptionActivationFacade implements ISubscriptionActivationFacade {
  private readonly customerSubscriptionsDataService = inject(CustomerSubscriptionsFacadeService);
  private readonly activationStateInternal$ = new BehaviorSubject<SubscriptionActivationState>({
    isActivating: null,
  });
  private readonly stopWaitingInternal$ = new Subject<boolean>();

  activationState$ = this.activationStateInternal$.asObservable();

  private scheduleStateReset(): void {
    asyncScheduler.schedule(() => this.activationStateInternal$.next({isActivating: null}));
  }

  startWaitingActivationOnlyFor(billingProduct: IBillingProductClient, productFamilyOnly = false): void {
    if (this.activationStateInternal$.value.isActivating !== null) {
      return;
    }

    this.activationStateInternal$.next({
      billingProduct,
      isActivating: true,
    });

    this.customerSubscriptionsDataService
      .waitForOnlyActivatedSubscription(
        billingProduct.id,
        productFamilyOnly ? billingProduct.productFamily : undefined,
        2000
      )
      .pipe(
        takeUntil(this.stopWaitingInternal$),
        catchError(error => {
          this.activationStateInternal$.next({
            billingProduct,
            isActivating: false,
            activationResult: {
              isActivated: false,
              error,
            },
          });
          return EMPTY;
        }),
        finalize(() => this.scheduleStateReset())
      )
      .subscribe(() => {
        this.activationStateInternal$.next({
          billingProduct,
          isActivating: false,
          activationResult: {isActivated: true},
        });
      });
  }

  stopWaitingActivation(): void {
    this.stopWaitingInternal$.next(true);
  }
}
