import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {ILimitDialogData} from '../../interfaces/limit-dialog.interface';
import {CollectionLimitDialogResult} from '../../enums/collection-limit-dialog-result';

@Component({
  templateUrl: './collection-limit-dialog.component.html',
  styleUrls: ['./collection-limit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CollectionLimitDialogComponent {
  get limit(): number {
    return this.dialogData.limit;
  }

  get upgradeUrl(): string | undefined {
    return this.dialogData.upgradeUrl;
  }

  get isTrial(): boolean {
    return this.dialogData.isTrial;
  }

  get isDuplicated(): boolean {
    return this.dialogData.isDuplicated;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<CollectionLimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: ILimitDialogData
  ) {}

  cancel(): void {
    this.dialogRef.close(CollectionLimitDialogResult.CANCEL);
  }

  handleUpdateClick(): void {
    this.dialogRef.close(CollectionLimitDialogResult.UPGRADE);
  }

  handleSwitchPlanClick(): void {
    this.dialogRef.close(CollectionLimitDialogResult.UPGRADE);
  }
}
