export type PxBillingProviderCheckoutSession = Record<string, unknown>;
export type PxBillingProviderPortalSession = Record<string, unknown>;

export interface IPxBillingProvider {
  init: (client: {api_key: string; site: string; iframeOnly: boolean}) => void;
  getInstance: () => IPxBillingProvider;
  openCheckout: (callbacks: IChargebeeHostedPage) => void;
  setPortalSession: (callback: () => void) => void;
  createChargebeePortal: () => IChargebeeCustomerPortal;
  logout: () => void;
  closeAll: () => void;
}

interface IChargebeeHostedPage {
  hostedPage: () => Promise<PxBillingProviderCheckoutSession>;
  loaded: () => void;
  close: () => void;
  success: (hostedPageId: unknown) => void;
  error: (error: Error) => void;
}

interface IChargebeeCustomerPortal {
  open: (callbacks: object) => void;
}
