export enum AppRoutes {
  LOGIN = 'login',
  LOGOUT = 'logout',
  MANAGER = '',
  EDITOR = 'edit',
  STYLE_AND_PREVIEW = 'slideshow-style',
  PREFERENCES = 'preferences',
  UNSUPPORTED_BROWSER = 'unsupported-browser',
  MOBILE = 'mobile',
  PAGE_NOT_FOUND = '404',
  AUTH = 'auth',
}

export enum PSSLinkableActionsExternal {
  NEW = 'px-collection-new',
  OPEN = 'px-collection-open',
  DELETE = 'px-collection-delete',
  SHARE = 'px-collection-share',
  SETTINGS = 'px-collection-settings',
  DOWNLOAD = 'px-collection-download',
  DUPLICATE = 'px-collection-duplicate',
  EDIT = 'px-collection-edit',
}

export enum LinkableActionRoutes {
  OPEN = 'open',
  NEW = 'new',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  DOWNLOAD = 'download',
  DELETE = 'delete',
  SHARE = 'share',
  SETTINGS = 'settings',
}
