import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslateService} from '@ngx-translate/core';

import {GlobalState} from './global.state';
import {DownloadFile, GlobalActionTypes, OfflineSnackbarHide, OfflineSnackbarShow} from './global.actions';
import {SnackbarComponent} from '../../shared/components/snackbar/snackbar.component';
import {selectOfflineSnackbarRef} from './global.selectors';
import {PSSPlatformEnvironment} from '../../platform-environment';

@Injectable()
export class GlobalEffects {
  redirectUnsupportedBrowser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_UNSUPPORTED_BROWSER),
        tap(() => {
          this.router.navigate(['/unsupported-browser']);
        })
      ),
    {dispatch: false}
  );

  redirectDesktopDevice = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_DESKTOP_DEVICE),
        tap(() => {
          this.router.navigate(['/']);
        })
      ),
    {dispatch: false}
  );

  redirectSupportedBrowser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_SUPPORTED_BROWSER),
        tap(() => {
          this.router.navigate(['/']);
        })
      ),
    {dispatch: false}
  );

  redirectMobileDevice = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.REDIRECT_MOBILE_DEVICE),
        tap(() => this.router.navigate(['/mobile']))
      ),
    {dispatch: false}
  );

  ConnectionOffline = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActionTypes.CONNECTION_OFFLINE),
      withLatestFrom(this.store$.select(selectOfflineSnackbarRef)),
      filter(([, snackbarRef]) => snackbarRef === null),
      map(() => {
        const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
          data: this.translate.instant('THEMES_PREVIEW_PAGE.CONNECTION_PROBLEM_MESSAGE'),
          verticalPosition: 'top',
          panelClass: 'px-offline-indicator',
        });
        return new OfflineSnackbarShow(snackBarRef);
      })
    )
  );

  ConnectionOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActionTypes.CONNECTION_ONLINE),
      withLatestFrom(this.store$.select(selectOfflineSnackbarRef)),
      filter(([, snackbarRef]) => snackbarRef !== null),
      map(([, snackbarRef]) => {
        snackbarRef.dismiss();
        return new OfflineSnackbarHide();
      })
    )
  );

  DownloadFile = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActionTypes.DOWNLOAD_FILE),
        map((action: DownloadFile) => action.url),
        tap(file_url => {
          const url = new URL(file_url, this.platform.CF_URL);
          const link = document.createElement('a');
          link.setAttribute('href', url.toString());
          link.setAttribute('download', 'download');
          link.click();
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<GlobalState>,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
    private readonly platform: PSSPlatformEnvironment
  ) {}
}
