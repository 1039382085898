import {ICustomerSubscription} from '../entities/customer-subscription';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export const CUSTOMER_SUBSCRIPTIONS: ICustomerSubscription[] = [
  {
    id: 'subId',
    nextBillingDate: 0,
    currentPeriodEnd: '',
    customer: {
      paymentSourceCards: [
        {
          brand: 'brand',
        },
      ],
    },
    package: {
      products: [
        {
          price: {
            id: 'priceId',
            name: 'price name',
            billingProductFamily: {
              productFamily: ProductFamily.PSS,
            },
            product: {
              id: 'productId',
              name: 'product name',
              metaData: {},
            },
            metaData: {},
          },
        },
      ],
    },
  },
  {
    id: 'subId2',
    nextBillingDate: 0,
    currentPeriodEnd: '',
    customer: {
      paymentSourceCards: [
        {
          brand: 'brand',
        },
      ],
    },
    package: {
      products: [
        {
          price: {
            id: 'priceId2',
            name: 'price name',
            billingProductFamily: {
              productFamily: ProductFamily.PSS,
            },
            product: {
              id: 'productId',
              name: 'product name',
              metaData: {},
            },
            metaData: {},
          },
        },
      ],
    },
  },
];

export const CUSTOMER_ONE_SUBSCRIPTION: ICustomerSubscription[] = [
  {
    id: 'subId',
    nextBillingDate: 0,
    currentPeriodEnd: '',
    customer: {
      paymentSourceCards: [
        {
          brand: 'brand',
        },
      ],
    },
    package: {
      products: [
        {
          price: {
            id: 'priceId',
            name: 'price name',
            billingProductFamily: {
              productFamily: ProductFamily.PSS,
            },
            product: {
              id: 'productId',
              name: 'product name',
              metaData: {},
            },
            metaData: {},
          },
        },
      ],
    },
  },
];

export const CUSTOMER_SUBSCRIPTIONS_WITHOUT_PAYMENT_SOURCE: ICustomerSubscription[] = [
  {
    id: 'subId',
    nextBillingDate: 0,
    currentPeriodEnd: '',
    customer: {
      paymentSourceCards: null,
    },
    package: {
      products: [
        {
          price: {
            id: 'priceId',
            name: 'price name',
            billingProductFamily: {
              productFamily: ProductFamily.PSS,
            },
            product: {
              id: 'productId',
              name: 'product name',
              metaData: {},
            },
            metaData: {},
          },
        },
      ],
    },
  },
];
