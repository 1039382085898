import {Action, createAction} from '@ngrx/store';

import {Slideshow} from 'apps/pss/src/app/models/slideshow.model';
import {MdsDialogRef} from '@pui/components/dialog';
import {NewSlideshowDialogComponent} from 'apps/pss/src/app/main/manager/components/new-slideshow-dialog/new-slideshow-dialog.component';

export enum SlideshowOptionsActionTypes {
  OPENED = '[Slideshow options] Opened',
  UPDATE = '[Slideshow options] Update',
  CREATE = '[Slideshow] Create',
  CREATE_SUCCESS = '[Slideshow] Create Success',
  CREATE_FAILURE = '[Slideshow] Create Failure',
  CLOSE = '[Slideshow options] Close',
  RESET_STATE = '[Slideshow] Reset state',
}

export class Opened implements Action {
  readonly type = SlideshowOptionsActionTypes.OPENED;

  constructor(
    readonly slideshow: Slideshow,
    readonly dialogRef: MdsDialogRef<NewSlideshowDialogComponent, boolean>
  ) {}
}

export class Update implements Action {
  readonly type = SlideshowOptionsActionTypes.UPDATE;

  constructor(public payload: Slideshow) {}
}

export class Create implements Action {
  readonly type = SlideshowOptionsActionTypes.CREATE;

  constructor(public payload: Slideshow) {}
}

export class CreateSuccess implements Action {
  readonly type = SlideshowOptionsActionTypes.CREATE_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateFailure implements Action {
  readonly type = SlideshowOptionsActionTypes.CREATE_FAILURE;

  constructor(public payload: any) {}
}

export class Close implements Action {
  readonly type = SlideshowOptionsActionTypes.CLOSE;

  constructor(public payload: {slideshow: Slideshow} | null) {}
}

export const duplicate = createAction('[Slideshow] Duplicate', (slideshow: Slideshow, unique_identifier: string) => ({
  slideshow,
  unique_identifier,
}));

export class ResetState implements Action {
  readonly type = SlideshowOptionsActionTypes.RESET_STATE;
}

export type SlideshowOptionsActionsUnion =
  | Opened
  | Update
  | Create
  | CreateSuccess
  | CreateFailure
  | Close
  | ReturnType<typeof duplicate>
  | ResetState;
