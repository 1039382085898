<div
  class="name textEllipsis"
  #nameContainerRef
  [puiTooltip]="name"
  [puiTooltipDisabled]="!hasTooltip"
  [puiTooltipShowDelay]="350"
  puiTooltipWidth="nowrap"
  puiTooltipPosition="bottom"
  data-qa="gallery-info-item-name"
>
  {{ name }}
</div>
