import {inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {intersection} from 'lodash';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagBodyClassService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly document = inject(DOCUMENT);
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly renderer: Renderer2;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init(): void {
    intersection(this.platform.ENABLED_FEATURES, this.platform.FEATURES_BODY_CLASSES).forEach(ff => this.setClass(ff));
  }

  setClass(featureFlag: string): void {
    this.renderer.addClass(this.document.body, featureFlag);
  }
}
