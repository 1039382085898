import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IBillingProductClientFeature, IBillingProductClientFeaturePart} from '@ps/pricing-domain';

@Component({
  selector: 'px-plan-feature',
  templateUrl: './plan-feature.component.html',
  styleUrls: ['./plan-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFeatureComponent {
  @Input() feature?: IBillingProductClientFeature;

  trackBy = (idx: number, item: IBillingProductClientFeaturePart): string => item.text;
}
